import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { pause, resume } from '@noriginmedia/norigin-spatial-navigation'
import Loading from 'components/loading/Loading'
import Splash from 'components/loading/Splash'
import VideoControl from 'components/videoControl/VideoControl'
import KEYCODE from 'libs/constants/keycode'
import useBroadcastStatus from 'libs/hooks/useBroadcastStatus'
import useHls from 'libs/hooks/useHls'
import useVideoEventHandler from 'libs/hooks/useVideoEventHandler'
import useVideoSetting from 'libs/hooks/useVideoSetting'
import {
  REQ_KINESIS_END,
  REQ_KINESIS_ENTER,
  REQ_KINESIS_GET_ROOM,
  REQ_KINESIS_RESTART,
} from 'libs/store/kinesis/kinesis.store'
import { convertSecondsToTime } from 'libs/utils'

interface Props {
  isFocused: boolean
  isLockPlayer?: boolean
  broadcastInfoData: Broadcast | null
  setIsLockPlayer: React.Dispatch<React.SetStateAction<boolean>>
}

const Video: React.FC<Props> = ({
  isFocused,
  isLockPlayer,
  broadcastInfoData,
  setIsLockPlayer,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [totalDuration, setTotalDuration] = useState<number>(0)
  const [videoBarDistance, setVideoBarDistance] = useState<number>(0)
  const [isShowControl, setIsShowControl] = useState<boolean>(true)
  const [playStatus, setPlayStatus] = useState<string>('') // 'play' | 'pause' | 'stop' | 'prev' | 'next' | ''
  const [isOnline, setIsOnline] = useState<boolean>(true)
  const [isShowLoading, setIsShowLoading] = useState<boolean>(true)
  const { isVod } = useBroadcastStatus()

  // 22TV_STANDARD1 모델일 때는 hls config 저화질로 설정함 (버퍼를 지속적으로 많이 받아올 수 없음)
  const [needRowProfile, setNeedRowProfile] = useState(false)
  useEffect(() => {
    if (!window.tizen) return

    const TV_22_STANDARD_MODELS = [
      'QBQ6D',
      'QBQ63',
      'QBQ60_DPP',
      'QBQ60',
      'M70BO',
      'M70B',
      'M50B',
      'UAU8000_L',
    ]
    const productinfo = webapis.productinfo
    const modelCode = productinfo && productinfo.getModel() ? productinfo.getModel() : undefined

    if (modelCode && TV_22_STANDARD_MODELS.includes(modelCode)) {
      setNeedRowProfile(true)
    }
  }, [])

  useHls(videoRef, needRowProfile)
  useVideoEventHandler(videoRef)
  useVideoSetting(videoRef)

  const dispatch = useDispatch()

  const handleOnCanPlayThrough = () => {
    const videoDuration = videoRef.current?.duration
    if (!videoDuration) return
    setTotalDuration(videoDuration)
    videoRef.current?.pause()
    setTimeout(() => {
      videoRef.current?.play()
      setIsShowLoading(false)
    }, 400)
  }

  /**
   * kinesis init useEffect
   */
  const callKinesisOnlyOnce = useRef<boolean>(false)
  useEffect(() => {
    const roomId = broadcastInfoData?.roomId
    if (!roomId) return
    if (callKinesisOnlyOnce.current) return
    callKinesisOnlyOnce.current = true
    dispatch(REQ_KINESIS_GET_ROOM({ roomId }))
    dispatch(
      REQ_KINESIS_ENTER({
        roomId,
      }),
    )
  }, [broadcastInfoData?.roomId, dispatch])

  /**
   * 비디오 재생 여부에 따라 포커싱 관련 키 이벤트 활성화 여부 처리 useEffect
   */
  useEffect(() => {
    isLockPlayer ? pause() : resume()
  }, [isLockPlayer])

  /**
   * 주기적으로 비디오 정보 갱신 useEffect
   */
  useEffect(() => {
    const updateInfoInterval = setInterval(() => {
      if (isShowLoading || !videoRef.current) return

      setCurrentTime(videoRef.current.currentTime)
      const barWidth = (Number(videoRef.current.currentTime) * 100) / Number(totalDuration)
      setVideoBarDistance(barWidth)
    }, 1000)
    return () => {
      clearInterval(updateInfoInterval)
    }
  }, [isShowLoading, broadcastInfoData, totalDuration])

  /**
   * 키다운 이벤트 매핑 useEffect
   * video area 내부에서 일어나는 리모컨 이벤트 제어
   * stop key에 대한 이벤트는 player.container.tsx 에서 제어 (back 버튼과 동일하게 처리)
   */
  const handleKeyDownVideo = useCallback(
    (e: KeyboardEvent) => {
      let customKeyCode: number | null = 0
      if (window.tizen) {
        try {
          window.tizen.tvinputdevice.registerKeyBatch([
            'MediaPlayPause',
            'MediaRewind',
            'MediaFastForward',
            'MediaPause',
            'MediaStop',
            'MediaPlay',
          ])
          switch (e.keyCode) {
            case window.tizen.tvinputdevice.getKey('MediaPlayPause').code:
              customKeyCode = KEYCODE.PLAY_AND_PAUSE
              break
            case window.tizen.tvinputdevice.getKey('MediaRewind').code:
              customKeyCode = KEYCODE.REWIND
              break
            case window.tizen.tvinputdevice.getKey('MediaFastForward').code:
              customKeyCode = KEYCODE.FAST_FORWARD
              break
            case window.tizen.tvinputdevice.getKey('MediaPause').code:
              customKeyCode = KEYCODE.PAUSE
              break
            case window.tizen.tvinputdevice.getKey('MediaStop').code:
              customKeyCode = KEYCODE.STOP
              break
            case window.tizen.tvinputdevice.getKey('MediaPlay').code:
              customKeyCode = KEYCODE.PLAY
              break
            default:
              customKeyCode = null
          }
        } catch (error) {
          console.error('Error exiting the Tizen app:', error)
        }
      }

      if (!isFocused) return

      const isRewind =
        e.keyCode === KEYCODE.LEFT ||
        e.keyCode === KEYCODE.REWIND ||
        customKeyCode === KEYCODE.REWIND

      const isFastForward =
        e.keyCode === KEYCODE.RIGHT ||
        e.keyCode === KEYCODE.FAST_FORWARD ||
        customKeyCode === KEYCODE.FAST_FORWARD

      // 되감기와 빨리감기는 vod 일 때만 가능
      if (isRewind && isVod && isLockPlayer && videoRef.current?.currentTime) {
        const adjustedTime = Math.max(videoRef.current?.currentTime - 10, 0)
        setCurrentTime(adjustedTime)
        const barWidth =
          ((Number(videoRef.current?.currentTime) - 10) * 100) / Number(totalDuration)
        setVideoBarDistance(barWidth)
        videoRef.current.currentTime = adjustedTime
        setPlayStatus('prev')
        setTimeout(() => {
          setPlayStatus('')
        }, 500)
        return
      }

      if (isFastForward && isVod && isLockPlayer && videoRef.current?.currentTime) {
        const adjustedTime = Math.min(videoRef.current?.currentTime + 10, Number(totalDuration))
        setCurrentTime(adjustedTime)
        const barWidth =
          ((Number(videoRef.current?.currentTime) + 10) * 100) / Number(totalDuration)
        setVideoBarDistance(barWidth)
        videoRef.current.currentTime = adjustedTime
        videoRef.current?.pause()
        setPlayStatus('next')
        setTimeout(() => {
          setPlayStatus('')
        }, 500)
        return
      }

      // live & vod 둘 다 해당
      if (
        e.keyCode === KEYCODE.OK ||
        e.keyCode === KEYCODE.PLAY_AND_PAUSE ||
        customKeyCode === KEYCODE.PLAY_AND_PAUSE
      ) {
        if (isLockPlayer) {
          dispatch(REQ_KINESIS_RESTART())
          setIsLockPlayer(false)
          setIsShowControl(false)
          videoRef.current?.play()
          setPlayStatus('play')
        } else {
          dispatch(REQ_KINESIS_END())
          setIsLockPlayer(true)
          setIsShowControl(true)
          videoRef.current?.pause()
          setPlayStatus('pause')
        }
        setTimeout(() => {
          setPlayStatus('')
        }, 500)
        return
      }

      if (e.keyCode === KEYCODE.PAUSE || customKeyCode === KEYCODE.PAUSE) {
        dispatch(REQ_KINESIS_END())
        setIsLockPlayer(true)
        setIsShowControl(true)
        videoRef.current?.pause()
        setPlayStatus('pause')
        setTimeout(() => {
          setPlayStatus('')
        }, 500)
        return
      }

      if (e.keyCode === KEYCODE.PLAY || customKeyCode === KEYCODE.PLAY) {
        dispatch(REQ_KINESIS_RESTART())
        setIsLockPlayer(false)
        setIsShowControl(false)
        videoRef.current?.play()
        setPlayStatus('play')
        setTimeout(() => {
          setPlayStatus('')
        }, 500)
        return
      }

      if (e.keyCode === KEYCODE.STOP || customKeyCode === KEYCODE.STOP) {
        videoRef.current?.pause()
        setPlayStatus('stop')
        setTimeout(() => {
          setPlayStatus('')
        }, 500)
        return
      }
    },
    [isVod, isLockPlayer, totalDuration, setIsLockPlayer, isFocused, dispatch],
  )
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownVideo)
    return () => {
      window.removeEventListener('keydown', handleKeyDownVideo)
    }
  }, [handleKeyDownVideo])

  /**
   * 온라인/오프라인 이벤트 매핑 useEffect
   */
  // 네트워크 연결시
  const handleOnline = useCallback(() => {
    //재생
    setIsLockPlayer(false)
    setIsShowControl(false)
    videoRef.current?.play()
    setPlayStatus('play')
    setTimeout(() => {
      setPlayStatus('')
    }, 500)
    setIsOnline(true)
  }, [setIsLockPlayer])

  // 네트워크 연결 유실시
  const handleOffline = useCallback(() => {
    //일시정지
    setIsLockPlayer(true)
    setIsShowControl(true)
    videoRef.current?.pause()
    setPlayStatus('pause')
    setTimeout(() => {
      setPlayStatus('')
    }, 500)
    setIsOnline(false)
  }, [setIsLockPlayer])

  useEffect(() => {
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [handleOnline, handleOffline])

  return (
    <VideoCover onChange={() => handleKeyDownVideo}>
      {isShowLoading && <Splash isShowSplash={false} />}
      <video id="videoEl" ref={videoRef} playsInline onCanPlayThrough={handleOnCanPlayThrough} />
      {isVod ? (
        <VideoControl
          currentTime={convertSecondsToTime(currentTime)}
          totalTime={convertSecondsToTime(totalDuration)}
          videoBarDistance={videoBarDistance}
          isShowControl={isShowControl}
          setIsShowControl={setIsShowControl}
        />
      ) : null}
      <VideoSignature>
        <VideoPlayPauseStop className={playStatus === 'play' ? 'active' : ''}>
          <img
            src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon-play-btn.svg?h=200&w=200&q=60&f=webp"
            alt="play button"
          />
        </VideoPlayPauseStop>
        <VideoPlayPauseStop className={playStatus === 'pause' ? 'active' : ''}>
          <img
            src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon-pause-btn.svg?h=200&w=200&q=60&f=webp"
            alt="pause button"
          />
        </VideoPlayPauseStop>
        <VideoPlayPauseStop className={playStatus === 'stop' ? 'active' : ''}>
          <img
            src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon-stop-btn.svg?h=200&w=200&q=60&f=webp"
            alt="stop button"
          />
        </VideoPlayPauseStop>
        <VideoPrev className={playStatus === 'prev' ? 'active' : ''}>
          <img
            src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon-btn-10sec-prev.svg?h=200&w=200&q=60&f=webp"
            alt="prev button"
          />
        </VideoPrev>
        <VideoNext className={playStatus === 'next' ? 'active' : ''}>
          <img
            src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon-btn-10sec-next.svg?h=200&w=200&q=60&f=webp"
            alt="next button"
          />
        </VideoNext>
      </VideoSignature>
      {!isOnline ? <Loading /> : null}
    </VideoCover>
  )
}

const VideoCover = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    top: 0%;
    left: 0%;
  }
`
const VideoSignature = styled.div`
  display: block;
`

const VideoStatusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0;
  transition: opacity 0.25s;
  height: 100%;
`
const VideoPlayPauseStop = styled(VideoStatusIcon)`
  width: 100%;
  &.active {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const VideoPrev = styled(VideoStatusIcon)`
  top: 0;
  left: 0;
  width: 50%;
  padding-right: 100px;
  &.active {
    opacity: 1;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
  }
`
const VideoNext = styled(VideoStatusIcon)`
  top: 0;
  right: 0;
  width: 50%;
  padding-left: 100px;
  &.active {
    opacity: 1;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
  }
`
export default Video
