const id = {
  date_format: {
    date: '{{day}}-{{month}}-{{year}}',
    schedule_time: '{{time,schedule_time}}',
  },

  time: {
    am: 'pagi',
    pm: 'sore',
    today: 'Hari ini',
    tomorrow: 'Besok',
    hour: 'jam {{hour}}',
    hour_minute: '{{hour}}:{{minute}}',
  },

  unit: {
    hundred_million: '00M',
    issue_count: '{{count}}',
    ten_thousand: '0K',
    thousand: 'K',
    won: '₩{{price}}',
  },

  add: 'Tambah',
  add_banned_terms: 'Enter the blocked words you want to add.',
  administrator_commented: 'Anda mendapat balasan.',
  administrator_froze_chat:
    'Obrolan langsung telah dibekukan!\nHanya admin yang dapat bergabung dalam obrolan sekarang.',
  administrator_not_issue_coupons: `Admins can't get coupons.`,
  administrator_unfroze_chat:
    'Obrolan langsung sudah tidak dibekukan!\nAnda dapat bergabung dengan obrolan sekarang.',
  age: 'Usia',
  agree_personal_info: 'Menyetujui Pengumpulan dan Penggunaan Informasi Pribadi kami',
  alarm_notifications: 'Dapatkan Notifikasi',
  all_coupon_issued: `Anda telah mendapatkan semua kupon.`,
  already_added_banned_term: 'The blocked word already exists.',
  auto_report_due_to_spam: 'Melaporkan diri sendiri karena spam.',
  available_until_period: 'Tersedia hingga {{period}}',
  banned_terms: 'Blocked words',
  banned_terms_added: 'Added to real-time blocked words list.',
  banned_terms_deleted: 'Removed from the real-time blocked words list.',
  banned_terms_included: 'Terdapat kata yang diblokir pada pesan Anda.',
  banned_terms_only_applicable_to_live:
    '* The blocked words that have been added will only apply to the Live stream.',
  broadcast_alarm_notified: 'Notifikasi akan dikirimkan ketika siaran dimulai.',
  broadcast_alarm_success: 'Notifikasi Siaran langsung ditetapkan.',
  broadcast_disconnected: 'Kami kehilangan sinyal siaran. Tunggu sejenak.',
  broadcast_ended: 'Siaran langsung telah berakhir.',
  broadcast_notice: 'Notifikasi siaran',
  broadcast_restored: 'Sinyal siaran telah dipulihkan.',
  broadcast_schedule_can_be_changed: 'Waktu siaran dapat berubah tergantung pada kondisi siaran.',
  broadcast_started: 'Siaran langsung telah dimulai.',
  broadcast_unstable: 'Ups! Siaran tidak stabil.',
  buttons_at_bottom_right: '※ Tombol kanan bawah >',
  cancel: 'Batal',
  cancel_notifications: 'Apakah Anda ingin membatalkan notifikasi Siaran langsung?',
  cancel_to_register_purchase_auth_winner: `Would you like to cancel the registration of the winner's information?`,
  canceled_broadcast:
    'Maaf! Siaran langsung ini telah dibatalkan.\nSampai jumpa kembali dalam siaran langsung berikutnya!',
  cannot_add_sold_out_to_wish_list:
    'Item yang habis tidak dapat dimasukkan dalam daftar keinginan Anda.',
  cannot_be_replayed: `Maaf! Anda tidak dapat memutar kembali siaran ini.`,
  cannot_edit_after_sign_in:
    'Nama panggilan Anda akan diperlihatkan ketika Anda bergabung dalam Obrolan langsung, dan informasi yang dimasukkan tidak dapat diedit.',
  cannot_edited_after_sent:
    'Setelah Anda mengirimkan balasan, itu tidak dapat diedit atau dihapus. Harap periksa sebelum mengirimkan.',
  change_nickname: 'Change nickname',
  change_to_portrait: 'Gunakan dalam mode potret.',
  chat_report: 'Laporkan pesan ini',
  chat_restrict: 'Disable',
  chat_restrict_included: 'Chat-disabled',
  chat_restricted_by_administrator: 'Dinonaktifkan oleh admin.',
  chat_restriction: 'Obrolan-dinonaktifkan',
  chat_warning: 'Penggunaan bahasa yang tidak pantas dapat membatasi penggunaan layanan.',
  chatroom_frozen: 'Ruang obrolan dibekukan.',
  check_broadcast_alarm_notifications: 'Apakah Anda ingin menerima notifikasi Siaran langsung?',
  check_code: 'Periksa kode entri Anda.',
  click_to_play_info_video: 'Click here to play the instructional video.',
  close: 'Tutup',
  confirm: 'Konfirmasi',
  congratulations_on_winning: 'Selamat, Anda telah memenangkan hadiah!',
  connection_unstable: 'Ups! Koneksi tidak stabil.\nTunggu sejenak.',
  contact_exclude_dashes: 'Kontak (tanpa “-”)',
  converting_to_vod: 'Siaran langsung telah berakhir dan dikonversi ke VOD. ',
  copy: 'Salin',
  coupon: 'Kupon',
  coupon_issued: `Anda telah mendapatkan kupon.`,
  coupon_received: 'Kupon diterima.',
  current_nickname: 'Current nickname',
  days_ago: 'hari yang lalu',
  delete: 'Hapus',
  delete_comment: 'Hapus komentar',
  delete_report: 'Hapus',
  deleted_comment_no_revert: 'Komentar yang dihapus tidak dapat dipulihkan.',
  discount: 'Diskon',
  do_cancel: 'Batal',
  do_copy: 'Salin',
  do_delete: 'Hapus',
  do_pin: 'Pin',
  do_report: 'Lapor',
  download: 'Unduh',
  edit: 'Edit',
  edit_comment: 'Edit komentar saya',
  email: 'Email',
  enter_at_lease_one: 'Masukkan minimal 1 karakter.',
  enter_at_least_3_purchase_number: 'Enter at least 3 digits.',
  enter_banned_terms: 'Enter a blocked word.',
  enter_chat: 'Bergabung Obrolan langsung',
  enter_code: 'Masukkan kode entri dalam {{codeLength}} digit.',
  enter_contact: 'Masukkan informasi kontak Anda.',
  enter_contact_number_or_email:
    'Enter contact information or email address. (not displayed, if not entered)',
  enter_email: 'Masukkan e-mail Anda.',
  enter_max_15_chars: 'Enter up to 15 characters',
  enter_max_40_chars: 'Enter up to 40 characters',
  enter_name: 'Masukkan nama Anda.',
  enter_nickname: 'Masukkan nama panggilan Anda.',
  enter_purchase_authentication_winner: 'Enter purchase event winners',
  enter_purchase_number: 'Masukkan nomor (pesanan) pembelian Anda.',
  enter_reply: 'Masukkan balasan Anda',
  enter_same_message_consecutively: 'Anda tidak dapat memasukkan pesan yang sama berturut-turut.',
  enter_short_time_consecutively:
    'Anda tidak dapat memasukkan pesan yang sama berturut-turut dalam waktu singkat.',
  enter_too_many_message:
    'Karena mengetik terlalu banyak, Anda telah dinonaktifkan dari mengirimkan pesan selama {{sec}} detik.',
  enter_without_space: 'Masukkan tanpa spasi.',
  entered_with_nickname: '{{nickname}} telah masuk.',
  entered_with_nickname_other: '{{nickname}} dan {{other}} telah masuk.',
  event_auto_exit_on_vod: 'After converting to VOD, the event automatically ends.',
  event_can_start_anytime_on_live: 'You can start an event at any time during live.',
  event_management: 'Manage events',
  event_start: 'Start event',
  exit: 'Keluar',
  expose_with_masking_and_max:
    '(Up to 50 persons, some parts of the information will be displayed as masked(*))',
  failed_issue_coupon: 'Gagal mendapatkan kupon.',
  failed_to_enter_message: 'Gagal mengobrol.',
  failed_to_enter_message_with_error:
    'Gagal mengobrol. \nKesalahan: [Gagal mencari informasi masuk. \nMasuk kembali.]',
  female: 'Wanita',
  fill_out_form_to_chat: 'Masukkan informasi di bawah untuk bergabung dalam obrolan.',
  freeze_chat: 'Bekukan obrolan',
  full: 'Lihat video lengkap',
  harassment_abuse: 'Pelecehan atau perundungan',
  hide_comments: 'Sembunyikan komentar',
  highlight: 'Lihat sorotan',
  hours_ago: ' jam yang lalu',
  info_area: 'Area informasi',
  info_image: 'Gambar',
  info_image_displayed: 'Gambar ini sedang ditampilkan kepada penonton.',
  information_message_to_winner: 'Announcement to the winners',
  intro: 'Intro',
  invalid_email_format: 'Alamat e-mail dalam format yang salah.',
  invalid_phone_format: 'Kontak dalam format yang salah.',
  is_where_see_all_comments:
    'Pada <bold>{{location}}</bold>, Anda dapat melihat semua balasan yang ditulis oleh admin.',
  issue_during_live: 'Anda hanya bisa mendapatkan kupon saat siaran langsung.',
  join_broadcast: 'Bergabung dalam Siaran langsung',
  join_chat_real_time: 'Bergabung dalam Obrolan langsung ini',
  join_secret: 'Bergabung dalam Siaran langsung Rahasia',
  leave_broadcast: 'Apakah Anda ingin meninggalkan siaran?',
  live_comment: 'Jawaban Langsung',
  live_comments_tab: 'Tab balasan langsung',
  live_intro: 'Intro Langsung',
  live_notice: 'Pengumuman Langsung',
  male: 'Pria',
  mins_ago: 'menit yang lalu',
  more_comments: 'Lihat {{count}} untuk komentar lainnya',
  mr: '<bold>{{name}}<bold>',
  must_agree_collect_use_info:
    'Anda harus menyetujui Pengumpulan dan Penggunaan Informasi Pribadi kami.',
  must_select_all_option: 'Semua opsi harus dipilih.',
  name: 'Nama',
  new_nickname: 'New nickname',
  nickname: 'Nama panggilan',
  no: 'Tidak',
  no_content: 'Masukkan balasan Anda untuk dikirim.',
  no_coupon: 'Tidak ada kupon yang tersedia.\nTunggu yang berikutnya!',
  no_intro: 'Tidak ada intro untuk siaran ini.',
  no_notice: 'Tidak ada pengumuman untuk siaran ini.',
  no_products_in_wish_list:
    'Tidak ada item dalam daftar keinginan Anda.\nTambahkan item yang Anda suka.',
  no_reply: 'Tidak ada balasan untuk stream ini.',
  no_shortcut: 'Tidak ada jalan pintas yang didaftarkan.',
  none: 'Tidak ada',
  note: 'Catatan',
  now: 'Tepat sebelum',
  obscene_message: 'Pesan cabul',
  obscenity_abuse: 'Cabul atau perundungan',
  only_possible_during_live: 'Hanya dimungkinkan saat tayangan langsung.',
  optional: 'Opsi',
  other_inappropriate_language: 'Komentar yang tidak pantas lainnya',
  participate: 'Bergabung',
  participate_success: 'Anda bergabung dalam acara pembelian!',
  phone_call_only_mobile: 'Panggilan telepon dapat dilakukan pada perangkat seluler.',
  phone_number: 'Panggil',
  pin_chat: 'Pin this message',
  pin_chat_to_top: 'Do you want to pin this message?',
  please_enter_nickname: 'Masukkan nama panggilan Anda.',
  please_input_accurately: 'Isi kolom dengan benar sesuai dengan acaranya.',
  price_differ_after_live: 'Harga dan manfaat dapat berubah ketikda Siaran langsung berakhir.',
  products: 'Item dijual',
  profanity_aggressive_expression: 'Penggunaan kata-kata kotor atau ekspresi kebencian',
  purchase: 'Beli',
  purchase_auth_icon_exposed_if_event_on:
    'When the Start Event is ON, the purchase event icon will be displayed in the player.',
  purchase_auth_result_icon_exposed_if_announcement_on:
    'When the Winner Announcement is ON, the purchase event icon is displayed in the player.',
  purchase_authentication: 'Acara Pembelian',
  purchase_authentication_event: 'Acara pembelian',
  purchase_authentication_event_winner: 'Pemenang acara pembelian',
  purchase_authentication_participant_info: 'Purchase Event participants info"',
  push_notifications: 'Notifikasi push',
  push_notifications_turned_off_in_my_lotte:
    'Notifikasi push mati.\nNyalakan Notifikasi push pada Lotte Saya > Pengaturan.',
  real_time_chat: 'Obrolan langsung',
  reason_for_reporting: 'Alasan pelaporan',
  receive_coupon: 'Dapatkan kupon',
  receive_notifications: 'Dapatkan notifikasi',
  receive_notifications_of_broadcast: 'Mendapatkan notifikasi siaran',
  receiving_notifications: 'Mendapatkan notifikasi',
  recent_shortcut: 'Shortcuts registered lately',
  register: 'Registrasi',
  register_broadcast_alarm: 'Meminta notifikasi Siaran langsung',
  register_notice_too: 'It will be also added to Live Announcements.',
  register_success: 'Winners info has been stored.',
  report_count: 'Laporan {{count}}',
  report_list: 'Obrolan dilaporkan',
  report_submitted: 'Laporan Anda telah diterima.',
  required: 'Diharuskan',
  select_reason_for_reporting: 'Pilih alasan untuk melaporkan pesan ini.',
  send: 'Kirim',
  send_report: 'Laporan diterima',
  service_restricted_with_false_report: 'Laporan palsu dapat membatasi penggunaan layanan.',
  set_nickname: 'Masukkan nama panggilan',
  share_broadcast: 'Bagikan siaran ini',
  share_livestream: 'Bagikan siaran ini',
  shortcut: 'Shortcut',
  shortcut_can_use_in_liveconsole: 'The shortcut feature is available in the Live console.',
  show_nickname_on_chat:
    'Nama panggilan Anda akan diperlihatkan ketika Anda bergabung dalam Obrolan langsung, dan informasi yang dimasukkan tidak dapat diedit.',
  sign_in: 'Masuk',
  sign_in_chat: 'Bergabung Obrolan langsung',
  sign_in_info_timed_out: 'Informasi masuk Anda telah kedaluwarsa. \nSilakan masuk lagi.',
  signed_in: 'Anda masuk sebagai {{nickname}}.',
  sold_out: 'Terjual habis',
  spam: 'Pesan spam',
  speed: 'X kecepatan',
  stop: 'Stop sharing',
  subscribe: 'Langganan',
  subscribe_shop: 'Apakah Anda ingin berlangganan Siaran langsung {{shopName}}?',
  subscriber_with_count: 'Pelanggan {{count}}',
  subscribing: 'Berlangganan',
  touch_turn_on_sound: 'Ketuk untuk menyalakan suara.',
  transmit: 'Bagikan ini',
  turn_on_notifications: 'Nyalakan notifikasi',
  unable_find_sign_in_path: 'Gagal mengidentifikasi jalur masuk.',
  unfreeze_chat: 'Unfreeze chat',
  unpin: 'Unpin',
  unpin_chat: 'Unpin this message',
  unpin_chat_to_top: 'Do you want to unpin this message?',
  unsubscribe_shop: 'Apakah Anda ingin berhenti berlangganan Siaran langsung {{shopName}}?',
  up_to_discount: 'iskon hingga {{discount}}',
  up_to_discount_upon_purchasing:
    'Diskon hingga {{discount}} untuk pembelian minimum sebesar {{threshold}}',
  upon_purchasing: 'Untuk pembelian minimal {{threshold}}',
  url_copied: 'URL telah disalin.',
  verify: 'Bergabung',
  view_more: 'Lihat lainnya',
  view_on_app: 'Lihat pada aplikasi',
  view_on_web: 'Lihat pada web',
  view_terms: 'Lihat Syarat dan Ketentuan.',
  viewable_full_horizontal_video:
    'Video horizontal akan dilihat layar penuh ketika ditonton secara horizontal.',
  wait_until_live_start:
    'Anda dapat bergabung dalam Obrolan langsung setelah Siaran Langsung dimulai.\nHarap tunggu hingga waktu tersebut.',
  winner_add: 'Enter the winners',
  winner_announcement: 'Announce the winners',
  winner_announcement_can_control_after_add:
    'After entering the winners information, you can turn on/off the winners announcement.',
  winner_info_can_modify_after_vod:
    'You can enter/edit winner information even after VOD converted.',
  winner_information: 'Winners information',
  winner_management: 'Manage winners',
  wish_list: 'Daftar Keinginan',
  wish_to_delete: 'Apakah Anda ingin menghapus?',
  wish_to_receive_notifications: 'Apakah Anda ingin mendapatkan notifikasi Siaran langsung?',
  wish_to_sign_in: 'Apakah Anda ingin masuk?',
  write_comments: 'tinggalkan komentar',
  writing_comments: 'Tinggalkan komentar kepada {{nickname}}',
  yes: 'Ya',

  common_brand: {
    ebay: 'Gmarket',
    auction: 'Auction',
    samsung: 'SAMSUNG',
    ldfs: 'Hotel Lotte',
    shinsegaelive: 'SHINSEGAE Live',
    lfmall: 'LF Mall',
    yanolja: 'yanolja',
    momq: 'MOMQ',
    hanatour: 'HanaTour',
    hanssem: 'HANSSEM',
    coway: 'COWAY',
    ellotte: 'Lotte 100',
    lotteon: 'Lotte On',
    livart: 'Livart',
    dongwonmall: 'Dongwon Mall',
    'lg-uplus': 'LG Uplus',
    abcmart: 'ABC Mart',
    posa: 'ePost Mall',
    innisfree: 'Inisfree',
    happyprince: 'Happy Prince',
    pastelmall: 'Pastel Mall',
  },
  main_page: {
    next_live: 'Langsung Berikutnya',
    top_10_live: '10 Teratas Langsung',
    brand: 'Merek',
    more: 'Lebih',
    policy_context:
      'Mobidoo Inc. adalah pialang pesanan melalui surat dan bukan merupakan pihak dalam pesanan melalui surat. Oleh karena itu, mereka tidak bertanggung jawab atas barang, transaksi, pengiriman, pengembalian uang, atau penjualan pesanan melalui surat lainnya, dan tanggung jawab tersebut ada pada pihak ketiga, seperti mereka yang meminta pialang pesanan melalui surat.',
    close_title: 'Keluar dari SauceTV?',
    close_cancel: 'Kembali',
    close_confirm: 'Keluar dari SauceTV',
    info_version: 'vérsi',
    info_device: 'peranti',
    info_language: 'bahasa Indonésia',
    info_platform: 'peron',
    info_region: 'wilayah',
    info_contact: 'menanyakan',
    info_close: 'tutup',
  },
  schedule_page: {
    today_live: 'LANGSUNG',
    tomorrow_live: 'LANGSUNG Besok',
    no_regist_live: 'Tidak ada konten.',
  },
  partner_page: {
    contents_tab: 'Konten',
    schedule_tab: 'Jadwal',
    no_regist_contents: 'Tidak ada konten.',
    no_regist_live: 'Tidak ada konten.',
  },
  player_page: {
    entered_with_nickname: '{{nickname}} bergabung',
    entered_with_nickname_other: '{{nickname}} dan {{n}} orang lain bergabung',
    preset_1: 'Halo',
    preset_2: 'Seperti',
    preset_3: 'Tersenyum',
    preset_4: 'Kecewa',
    preset_5: 'Bosan',
    preset_6: 'Tepuk tangan',
    preset_7: 'Terkejut',
    icon_notice: 'Pemberitahuan',
    icon_chat: 'Obrolan',
    modal_qr_scan_info: 'SCAN SAYA',
    modal_live_intro_tab: 'Info',
    modal_live_intro_no_cont: 'Tidak ada konten.',
    modal_live_notice_tab: 'Pemberitahuan',
    modal_live_notice_no_cont: 'Tidak ada konten.',
    modal_live_reply_tab: 'Balasan',
    modal_live_reply_no_cont: 'Tidak ada konten.',
    modal_close: 'Tutup',
    banner_default_text: '더 많은 방송 보기',

    product_title: 'Dijual',
    product_info_text: 'Harga dan manfaat dapat berubah setelah siaran langsung selesai.',
    product_soldout: 'Habis terjual',
    before_chat_text:
      'Anda dapat bergabung ketika live chat dimulai. \nMohon tunggu sampai kita mulai siaran langsung.',
    before_notice: 'Perhatikan',
  },
}

export default id
