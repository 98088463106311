const en = {
  date_format: {
    date: '{{month}}-{{day}}-{{year}}',
    schedule_time: '{{time,schedule_time}}',
  },

  time: {
    am: 'AM',
    pm: 'PM',
    today: 'Today',
    tomorrow: 'Tomorrow',
    hour: '{{hour}} {{amPm}}',
    hour_minute: '{{hour}}:{{minute}}',
  },

  unit: {
    hundred_million: '00M',
    issue_count: '{{count}}',
    ten_thousand: '0K',
    thousand: 'K',
    won: '₩{{price}}',
  },

  add: 'Add',
  add_banned_terms: 'Enter the blocked words you want to add.',
  administrator_commented: 'You got a reply.',
  administrator_froze_chat: 'Live chat has frozen!\nOnly admins can join the chat now.',
  administrator_not_issue_coupons: `Admins can't get coupons.`,
  administrator_unfroze_chat: 'Live chat has unfrozen!\nYou can join the chat now.',
  age: 'Age',
  agree_personal_info: 'Agreed to our Collection and Use of Personal Information',
  alarm_notifications: 'Get Notification',
  all_coupon_issued: `You've got all coupons.`,
  already_added_banned_term: 'The blocked word already exists.',
  auto_report_due_to_spam: 'Self reported due to spamming.',
  available_until_period: 'Available until {{period}}',
  banned_terms: 'Blocked words',
  banned_terms_added: 'Added to real-time blocked words list.',
  banned_terms_deleted: 'Removed from the real-time blocked words list.',
  banned_terms_included: 'There is a blocked word in your message.',
  banned_terms_only_applicable_to_live:
    '* The blocked words that have been added will only apply to the Live stream.',
  broadcast_alarm_notified: 'A notification will be sent when the stream starts.',
  broadcast_alarm_success: 'Live stream notification is set.',
  broadcast_disconnected: 'We have lost the stream signal. Wait for a moment.',
  broadcast_ended: 'Live stream has ended.',
  broadcast_notice: 'Stream notification',
  broadcast_restored: 'The stream signal has been restored.',
  broadcast_schedule_can_be_changed:
    'The streaming time is subject to change according to streaming circumstances.',
  broadcast_started: 'Live stream has started.',
  broadcast_unstable: 'Oops! The steaming is not stable.',
  buttons_at_bottom_right: '※ Bottom right button >',
  cancel: 'Cancel',
  cancel_notifications: 'Do you want to cancel the Live stream notification?',
  cancel_to_register_purchase_auth_winner: `Would you like to cancel the registration of the winner's information?`,
  canceled_broadcast: 'Sorry!This Live stream has been canceled.\nSee you in the next one!',
  cannot_add_sold_out_to_wish_list: 'Out-of-stock items cannot be added to your wish list.',
  cannot_be_replayed: `Sorry! You can't replay this stream.`,
  cannot_edit_after_sign_in:
    'Your nickname will be shown when you join Live chat, and the entered information cannot be edited.',
  cannot_edited_after_sent:
    'Once you send a reply, it cannot be edited or deleted. Please check before sending.',
  change_nickname: 'Change nickname',
  change_to_portrait: 'Use in portrait mode.',
  chat_report: 'Report this message',
  chat_restrict: 'Disable',
  chat_restrict_included: 'Chat-disabled',
  chat_restricted_by_administrator: 'Disabled by admin.',
  chat_restriction: 'Chat-disabled',
  chat_warning: 'The use of inappropriate language may restrict service usage.',
  chatroom_frozen: 'The chatroom is frozen.',
  check_broadcast_alarm_notifications: 'Would you like to get Live stream notifications?',
  check_code: 'Check your entry code.',
  click_to_play_info_video: 'Click here to play the instructional video.',
  close: 'Close',
  confirm: 'Confirm',
  congratulations_on_winning: 'Congratulations, you have won the prize!',
  connection_unstable: 'Oops! The connection is not stable.\nWait for a moment.',
  contact_exclude_dashes: 'Contact (without “-”)',
  converting_to_vod: 'Live stream has ended and is converting to VOD. ',
  copy: 'Copy',
  coupon: 'Coupons',
  coupon_issued: `You've got the coupon.`,
  coupon_received: 'Coupon(s) received.',
  current_nickname: 'Current nickname',
  days_ago: 'days ago',
  delete: 'Delete',
  delete_comment: 'Delete comment',
  delete_report: 'Delete',
  deleted_comment_no_revert: 'Deleted comments cannot be restored.',
  discount: 'Discount',
  do_cancel: 'Cancel',
  do_copy: 'Copy',
  do_delete: 'Delete',
  do_pin: 'Pin',
  do_report: 'Report',
  download: 'Download',
  edit: 'Edit',
  edit_comment: 'Edit my comments',
  email: 'Email',
  enter_at_lease_one: 'Enter at least 1 character.',
  enter_at_least_3_purchase_number: 'Enter at least 3 digits.',
  enter_banned_terms: 'Enter a blocked word.',
  enter_chat: 'Join Live chat',
  enter_code: 'Enter the entry code in {{codeLength}} digits.',
  enter_contact: 'Enter your contact information.',
  enter_contact_number_or_email:
    'Enter contact information or email address. (not displayed, if not entered)',
  enter_email: 'Enter your e-mail.',
  enter_max_15_chars: 'Enter up to 15 characters',
  enter_max_40_chars: 'Enter up to 40 characters',
  enter_name: 'Enter your name.',
  enter_nickname: 'Enter your nickname.',
  enter_purchase_authentication_winner: 'Enter purchase event winners',
  enter_purchase_number: 'Enter your purchase(order) number.',
  enter_reply: 'Enter your reply',
  enter_same_message_consecutively: 'You cannot enter the same message consecutively.',
  enter_short_time_consecutively:
    'You cannot enter the same message consecutively in a short period.',
  enter_too_many_message:
    'Due to excessive typing, you have been disabled from sending messages for {{sec}} seconds.',
  enter_without_space: 'Enter without blanks.',
  entered_with_nickname: '{{nickname}} has entered.',
  entered_with_nickname_other: '{{nickname}} and {{other}} have entered.',
  event_auto_exit_on_vod: 'After converting to VOD, the event automatically ends.',
  event_can_start_anytime_on_live: 'You can start an event at any time during live.',
  event_management: 'Manage events',
  event_start: 'Start event',
  exit: 'Exit',
  expose_with_masking_and_max:
    '(Up to 50 persons, some parts of the information will be displayed as masked(*))',
  failed_issue_coupon: 'Failed to get the coupon.',
  failed_to_enter_message: 'Failed to chat.',
  failed_to_enter_message_with_error:
    'Failed to chat. \nError: [Failed to search the sign-in information. \nSign in again.]',
  female: 'Female',
  fill_out_form_to_chat: 'Enter the information below to join the chat.',
  freeze_chat: 'Freeze chat',
  full: 'View full video',
  harassment_abuse: 'Harassing or bullying',
  hide_comments: 'Hide comments',
  highlight: 'View highlights',
  hours_ago: ' hours ago',
  info_area: 'Information area',
  info_image: 'Images',
  info_image_displayed: 'This image is being displayed to the viewers.',
  information_message_to_winner: 'Announcement to the winners',
  intro: 'Intro',
  invalid_email_format: 'The e-mail address is in a wrong format.',
  invalid_phone_format: 'The contact is in a wrong format.',
  is_where_see_all_comments:
    'In <bold>{{location}}</bold>, you can see all replies written by admin.',
  issue_during_live: 'You can get the coupon only during live.',
  join_broadcast: 'Join the Live stream',
  join_chat_real_time: 'Join this Live chat',
  join_secret: 'Join the Secret Live stream',
  leave_broadcast: 'Do you want to leave the stream?',
  live_comment: 'Live Replies',
  live_comments_tab: 'Live reply tab',
  live_intro: 'Live Intro',
  live_notice: 'Live Announcements',
  male: 'Male',
  mins_ago: 'minutes ago',
  more_comments: 'View {{count}} more comments',
  mr: '<bold>{{name}}<bold>',
  must_agree_collect_use_info: 'You must agree to our Collection and Use of Personal Information.',
  must_select_all_option: 'All options shall be selected.',
  name: 'Name',
  new_nickname: 'New nickname',
  nickname: 'Nickname',
  no: 'No',
  no_content: 'Enter your reply to send.',
  no_coupon: 'No coupons available.\nWait for the next ones!',
  no_intro: 'No intro for this stream.',
  no_notice: 'No announcements for this stream.',
  no_products_in_wish_list: 'No items in your wishlist.\nAdd items you like.',
  no_reply: 'No replies for this stream.',
  no_shortcut: 'No shortcuts registered.',
  none: 'None',
  note: 'Note',
  now: 'Right before',
  obscene_message: 'Obscene messages',
  obscenity_abuse: 'Obscene or bullying',
  only_possible_during_live: 'Only possible during live.',
  optional: 'Options',
  other_inappropriate_language: 'Other inappropriate remarks',
  participate: 'Join',
  participate_success: 'You joined the purchase event!',
  phone_call_only_mobile: 'Phone calls can be made on mobile devices.',
  phone_number: 'Call',
  pin_chat: 'Pin this message',
  pin_chat_to_top: 'Do you want to pin this message?',
  please_enter_nickname: 'Enter your nickname.',
  please_input_accurately: 'Enter the fields correctly as they are used for event.',
  price_differ_after_live: 'The prices and benefits may change when Live stream ends.',
  products: 'Items for sale',
  profanity_aggressive_expression: 'Use of profanity or expressions of hatred',
  purchase: 'Buy',
  purchase_auth_icon_exposed_if_event_on:
    'When the Start Event is ON, the purchase event icon will be displayed in the player.',
  purchase_auth_result_icon_exposed_if_announcement_on:
    'When the Winner Announcement is ON, the purchase event icon is displayed in the player.',
  purchase_authentication: 'Purchase Event',
  purchase_authentication_event: 'Purchase event',
  purchase_authentication_event_winner: 'Purchase event winners',
  purchase_authentication_participant_info: 'Purchase Event participants info"',
  push_notifications: 'Push notification',
  push_notifications_turned_off_in_my_lotte:
    'Push notification is off.\nTurn on the Push notification in My Lotte > Settings.',
  real_time_chat: 'Live chat',
  reason_for_reporting: 'Reason for report',
  receive_coupon: 'Get coupons',
  receive_notifications: 'Get notified',
  receive_notifications_of_broadcast: 'Getting stream notification',
  receiving_notifications: 'Getting notification',
  recent_shortcut: 'Shortcuts registered lately',
  register: 'Registration',
  register_broadcast_alarm: 'Request Live stream notification',
  register_notice_too: 'It will be also added to Live Announcements.',
  register_success: 'Winners info has been stored.',
  report_count: 'Report {{count}}',
  report_list: 'Chat-reported',
  report_submitted: 'Your report has been received.',
  required: 'Required',
  select_reason_for_reporting: 'Choose a reason for reporting this message.',
  send: 'Send',
  send_report: 'Report accepted',
  service_restricted_with_false_report: 'False reports may restrict service usage.',
  set_nickname: 'Enter nickname',
  share_broadcast: 'Share this stream',
  share_livestream: 'Share this stream',
  shortcut: 'Shortcut',
  shortcut_can_use_in_liveconsole: 'The shortcut feature is available in the Live console.',
  show_nickname_on_chat:
    'Your nickname will be shown when you join Live chat, and the entered information cannot be edited.',
  sign_in: 'Sign in',
  sign_in_chat: 'Join Live chat',
  sign_in_info_timed_out: 'Your sign-in information has expired. \nPlease sign in again.',
  signed_in: 'You are signed in as {{nickname}}.',
  sold_out: 'Sold out',
  spam: 'Spam message',
  speed: 'X speed',
  stop: 'Stop sharing',
  subscribe: 'Subscribe',
  subscribe_shop: `Do you want to subscribe to {{shopName}}'s Live stream?`,
  subscriber_with_count: 'Subscribers {{count}}',
  subscribing: 'Subscribed',
  touch_turn_on_sound: 'Tap to turn on the sound.',
  transmit: 'Share this',
  turn_on_notifications: 'Turn on notifications',
  unable_find_sign_in_path: 'Failed to identify the sign-in path.',
  unfreeze_chat: 'Unfreeze chat',
  unpin: 'Unpin',
  unpin_chat: 'Unpin this message',
  unpin_chat_to_top: 'Do you want to unpin this message?',
  unsubscribe_shop: `Do you want to unsubscribe from {{shopName}}'s Live stream?`,
  up_to_discount: 'Up to {{discount}} discount',
  up_to_discount_upon_purchasing:
    'Up to {{discount}} discount on minimum purchase of {{threshold}}',
  upon_purchasing: 'For purchases of at least {{threshold}}',
  url_copied: 'The URL has been copied.',
  verify: 'Join',
  view_more: 'View more',
  view_on_app: 'View on the app',
  view_on_web: 'View on the web',
  view_terms: 'View Terms and Conditions.',
  viewable_full_horizontal_video:
    'Horizontal video will be viewed in full-screen when watched horizontally.',
  wait_until_live_start:
    'You can join the Live chat once the Live stream starts.\nPlease wait until then.',
  winner_add: 'Enter the winners',
  winner_announcement: 'Announce the winners',
  winner_announcement_can_control_after_add:
    'After entering the winners information, you can turn on/off the winners announcement.',
  winner_info_can_modify_after_vod:
    'You can enter/edit winner information even after VOD converted.',
  winner_information: 'Winners information',
  winner_management: 'Manage winners',
  wish_list: 'Wish List',
  wish_to_delete: 'Do you want to delete?',
  wish_to_receive_notifications: 'Do you want to get Live stream notifications?',
  wish_to_sign_in: 'Would you like to sign in?',
  write_comments: 'leave a comment',
  writing_comments: 'leaving a comment to {{nickname}}',
  yes: 'Yes',

  common_brand: {
    ebay: 'Gmarket',
    auction: 'Auction',
    samsung: 'SAMSUNG',
    ldfs: 'Hotel Lotte',
    shinsegaelive: 'SHINSEGAE Live',
    lfmall: 'LF Mall',
    yanolja: 'yanolja',
    momq: 'MOMQ',
    hanatour: 'HanaTour',
    hanssem: 'HANSSEM',
    coway: 'COWAY',
    ellotte: 'Lotte 100',
    lotteon: 'Lotte On',
    livart: 'Livart',
    dongwonmall: 'Dongwon Mall',
    'lg-uplus': 'LG Uplus',
    abcmart: 'ABC Mart',
    posa: 'ePost Mall',
    innisfree: 'Inisfree',
    happyprince: 'Happy Prince',
    pastelmall: 'Pastel Mall',
  },
  main_page: {
    next_live: 'Next LIVE',
    top_10_live: 'TOP 10 LIVE',
    brand: 'Brand',
    more: 'More',
    policy_context:
      'Mobidoo Inc. is a mail order broker and is not a party to mail order. Therefore, they are not liable for goods, transactions, delivery, refunds, or other mail order sales, and such responsibility rests with third parties, such as those who requested mail order brokerage.',
    close_title: 'Exit SauceTV?',
    close_cancel: 'Go back',
    close_confirm: 'Exit SauceTV',
    info_version: 'Version',
    info_device: 'Device',
    info_language: 'Language',
    info_platform: 'Platform',
    info_region: 'Region',
    info_contact: 'Contact',
    info_close: 'Close',
  },
  schedule_page: {
    today_live: 'LIVE',
    tomorrow_live: 'Tomorrow LIVE',
    no_regist_live: 'No contents',
  },
  partner_page: {
    contents_tab: 'Contents',
    schedule_tab: 'Schedule',
    no_regist_contents: 'No contents',
    no_regist_live: 'No contents',
  },
  player_page: {
    entered_with_nickname: '{{nickname}} joined',
    entered_with_nickname_other: '{{nickname}} and {{other}} others joined',
    preset_1: 'Hello',
    preset_2: 'Like',
    preset_3: 'Smile',
    preset_4: 'Dissapointed',
    preset_5: 'Bored',
    preset_6: 'Clap',
    preset_7: 'Suprised~',
    icon_notice: 'Notice',
    icon_chat: 'Chat',
    modal_qr_scan_info: 'SCAN ME',
    modal_live_intro_tab: 'Info',
    modal_live_intro_no_cont: 'No contents',
    modal_live_notice_tab: 'Notice',
    modal_live_notice_no_cont: 'No contents',
    modal_live_reply_tab: 'Reply',
    modal_live_reply_no_cont: 'No contents',
    modal_close: 'Close',
    banner_default_text: '더 많은 방송 보기',

    product_title: 'For sale',
    product_info_text: 'Prices and benefits may change upon live stream conclusion.',
    product_soldout: 'Soldout',
    before_chat_text: 'You can join once the live chat starts. \nPlease wait until then.',
    before_notice: 'Notice',
  },
}

export default en
