import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  FocusContext,
  init,
  setFocus,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import Card from 'components/card/Card'
import ScheduleCard from 'components/card/ScheduleCard'
import Tab from 'components/tab/Tab'
import { PARTNER_PAGE_NAME, PARTNERPAGE_TABNAME } from 'libs/constants'
import { SMOOTH_DURATION } from 'libs/constants/delay'
import i from 'libs/i18n'
import { RootState } from 'libs/store'
import { SET_NOW_POSITION } from 'libs/store/interaction/interaction.store'
import { getFocusKey, scrollToSmooth, toCountFormat } from 'libs/utils'

init({
  throttle: 300,
  throttleKeypresses: true,
})

const EmptyContent = () => {
  return (
    <div className="emptyContents schedule" style={{ color: '#fff' }}>
      <img
        src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon_smile.svg?h=200&w=200&q=60&f=webp"
        alt="empty icon"
      />
      <h1>{i.t('partner_page.no_regist_contents')}</h1>
    </div>
  )
}

const ContentList = ({ data, onPressEnter, onFocusCard }) => {
  if (!data) return <EmptyContent />
  return (
    <>
      {data.map(
        (
          {
            broadcastId,
            broadcastName,
            broadcastStateCode,
            partnerId,
            totalDuration,
            productListDetail,
            thumbnailList,
            room,
          },
          index: number,
        ) => (
          <Card
            key={broadcastId}
            broadcastStateCode={broadcastStateCode}
            broadcastName={broadcastName}
            columnNum={2}
            incomingCounterInfo={toCountFormat(room?.incomingCounterInfo ?? 0)}
            index={index}
            focusKey={getFocusKey({ section: PARTNERPAGE_TABNAME.CONTENTS, partnerId, index })}
            onFocus={onFocusCard}
            onEnterPress={() => {
              onPressEnter({ partnerId, broadcastId, index, section: PARTNERPAGE_TABNAME.CONTENTS })
            }}
            representativeProduct={productListDetail?.length === 0 ? null : productListDetail[0]}
            shopName={i.t(`common_brand.${partnerId}`)}
            thumbnail={
              thumbnailList?.filter(({ isRepresentative }) => isRepresentative)[0]?.thumbnailUrl +
                '?h=405&w=540' || ''
            }
            totalDuration={totalDuration?.toString()}
            type={'feed'}
          />
        ),
      )}
    </>
  )
}

const ScheduleList = ({ data, onPressEnter, onFocusCard }) => {
  if (!data) return <EmptyContent />
  return (
    <>
      {data.map(
        (
          {
            broadcastId,
            broadcastName,
            partnerId,
            programmingStartDt,
            thumbnailList,
            productListDetail,
          },
          index: number,
        ) => (
          <ScheduleCard
            key={broadcastId}
            broadcastName={broadcastName}
            columnNum={2}
            index={index}
            focusKey={getFocusKey({ section: PARTNERPAGE_TABNAME.SCHEDULE, partnerId, index })}
            onFocus={onFocusCard}
            onEnterPress={() => {
              onPressEnter({ partnerId, broadcastId, index, section: PARTNERPAGE_TABNAME.SCHEDULE })
            }}
            programmingStartDt={programmingStartDt}
            representativeProduct={productListDetail?.length === 0 ? null : productListDetail[0]}
            shopName={i.t(`common_brand.${partnerId}`)}
            source={'partner'}
            thumbnail={
              thumbnailList?.filter(({ isRepresentative }) => isRepresentative)[0]?.thumbnailUrl +
                '?h=405&w=540' || ''
            }
            type={'feed'}
          />
        ),
      )}
    </>
  )
}

const PartnerContentsContainer: React.FC = () => {
  const partnerBroadcastList = useSelector(
    (state: RootState) => state.app.selectedPartnerBroadcastList,
  )
  const partnerScheduleList = useSelector(
    (state: RootState) => state.app.selectedPartnerScheduleList,
  )

  const lastPositionInfo = useSelector((state: RootState) => state.interaction.nowPosition)

  const [selectedTabName, setSelectedTabName] = useState<string>(PARTNERPAGE_TABNAME.CONTENTS)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { ref, focusKey, focusSelf } = useFocusable({
    focusKey: 'brand',
  })

  const tabList = {
    ['contents']: (
      <span key={'content_tab'} className="tab_text">
        {i.t('partner_page.contents_tab')}
      </span>
    ),
    ['schedule']: (
      <span key={'schedule_tab'} className="tab_text">
        {i.t('partner_page.schedule_tab')}
      </span>
    ),
  }

  const onTabFocus = ({ layout, tabName }) => {
    const { y } = layout
    setSelectedTabName(tabName)
    scrollToSmooth(y, SMOOTH_DURATION)
  }

  const onFocusCard = (layout, extraProps, details) => {
    const { y } = layout
    const { event } = details
    if (!event) {
      scrollTo({ top: y, behavior: 'instant' })
    } else {
      scrollToSmooth(y, SMOOTH_DURATION)
    }
  }

  const onPressEnter = ({ broadcastId, partnerId, index, section }) => {
    const positionInfo = {
      page: PARTNER_PAGE_NAME,
      partnerId,
      broadcastId,
      section,
      index,
    }
    dispatch(SET_NOW_POSITION(positionInfo))

    navigate(`/player?broadcastId=${broadcastId}&partnerId=${partnerId}`)
  }

  /**
   * 이전화면에서 선택한 요소 포커싱 useLayoutEffect
   */
  useLayoutEffect(() => {
    const focusKey = getFocusKey(lastPositionInfo)
    const { section } = lastPositionInfo

    if (section === PARTNERPAGE_TABNAME.SCHEDULE || section === PARTNERPAGE_TABNAME.CONTENTS) {
      setSelectedTabName(section)
      setFocus(focusKey)
      window.history.replaceState({}, document.title)
    } else {
      focusSelf()
    }
  }, [focusSelf, lastPositionInfo])

  return (
    <FocusContext.Provider value={focusKey}>
      <PartnerContentsWrapper ref={ref}>
        <TabListWrapper>
          {Object.values(PARTNERPAGE_TABNAME).map((tabName, index) => (
            <Tab
              pageName={PARTNER_PAGE_NAME}
              key={tabName}
              index={index}
              onFocus={(layout) => {
                onTabFocus({ layout, tabName })
              }}
              isSelected={selectedTabName === tabName}
              tabName={tabName}
            >
              {tabList[tabName]}
            </Tab>
          ))}
        </TabListWrapper>
        <ContentListWrapper className={`page_${selectedTabName}_wrapper`}>
          {selectedTabName === 'contents' && (
            <ContentList
              data={partnerBroadcastList}
              onPressEnter={onPressEnter}
              onFocusCard={onFocusCard}
            />
          )}
          {selectedTabName === 'schedule' && (
            <ScheduleList
              data={partnerScheduleList}
              onPressEnter={onPressEnter}
              onFocusCard={onFocusCard}
            />
          )}
        </ContentListWrapper>
      </PartnerContentsWrapper>
    </FocusContext.Provider>
  )
}

const ContentListWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 1;
  flex-grow: 1;
`
const TabListWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.black2};
`
const PartnerContentsWrapper = styled.div`
  .focusedTabBtn {
    input:checked + label > span {
      border-radius: 10px 10px 0 0;
      background-color: #fff;
      color: #16161a;
    }
  }

  .tab_text {
    padding: 21px 50px;
    font-size: 48px;
    font-weight: bold;
    color: #bebebe;
  }

  .none {
    display: none;
  }

  .page_contents_wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0px 0;

    .card {
      margin-bottom: 15px;
    }
  }

  .page_schedule_wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0px 0;
  }

  .emptyContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    width: 100%;

    img {
      margin-bottom: 20px;
    }

    h1 {
      font-size: 60px;
      line-height: 100px;
    }
  }
`

export default PartnerContentsContainer
