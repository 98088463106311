import { TYPE_MESSAGE } from 'libs/@types/enums'
import i from 'libs/i18n'
import { ApiBroadcastFrontResponse, ApiBroadcastInternalResponse } from './@types/api'

export const convertToCurrency = (price: string | number) => {
  if (typeof price === 'number') {
    return `${price.toLocaleString('kr')}원`
  }
  if (typeof price === 'string') {
    const convertedPrice = price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `${convertedPrice}원`
  }
}

export const msToTime = (s?: string) => {
  if (!s) return
  let _s = Math.floor(Number(s))
  function pad(n: number, z: number) {
    z = z || 2
    return ('00' + n).slice(-z)
  }

  const secs = _s % 60
  _s = (_s - secs) / 60
  const mins = _s % 60
  const hrs = (_s - mins) / 60
  return pad(hrs, 2) + ':' + pad(mins, 2) + ':' + pad(secs, 2)
}

export const incomingCalc = (count?: number) => {
  if (!count) return null
  let calcCount = count / 1000
  let printReactionCount = '0'

  if (calcCount >= 10) {
    calcCount = count / 10000
    printReactionCount = calcCount.toFixed(1) + '만'
  } else if (calcCount >= 1) {
    printReactionCount = calcCount.toFixed(1) + '천'
  } else {
    printReactionCount = String(count)
  }

  return printReactionCount
}

export const getHourWord = (targetDate: Date) => {
  let word = ''

  const today = new Date()
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))

  if (targetDate.getMonth() === today.getMonth() && targetDate.getDate() === today.getDate()) {
    word += i.t('time.today')
  } else if (
    targetDate.getMonth() === tomorrow.getMonth() &&
    targetDate.getDate() === tomorrow.getDate()
  ) {
    word += i.t('time.tomorrow')
  }

  return word
}

export const getFocusKey = ({ section, partnerId, index }) => {
  return `${section}-${partnerId}-${index}`
}

export const dateParser = (dateStr: string) => {
  const _date = new Date(dateStr)
  const [year, month, date, hours, minutes] = [
    _date.getFullYear(),
    _date.getMonth() + 1,
    _date.getDate(),
    _date.getHours(),
    _date.getMinutes(),
  ]

  return `${year}. ${month}. ${date}. ${hours}:${minutes == 0 ? '00' : minutes}`
}

export const dateParserDate = (dateStr: string) => {
  const _date = new Date(dateStr)
  const [month, date] = [_date.getMonth() + 1, _date.getDate()]

  return `${month}. ${date}.`
}

export const dateParserTime = (dateStr: string) => {
  const _date = new Date(dateStr)
  const [hours, minutes] = [_date.getHours(), _date.getMinutes()]

  const hourWord = getHourWord(_date)
  const hour = hours
  let remainHour = hour === 0 || hour === 12 ? 12 : hour % 12

  if (hourWord) {
    if (minutes === 0) {
      return `${hourWord} ${remainHour}.`
    }
    return `${hourWord} ${remainHour}. ${minutes}.`
  }
  remainHour = hour === 12 ? hour : hour % 12

  if (minutes === 0) {
    return `${remainHour}.`
  }
  return `${remainHour}. ${minutes}.`
}

export const convertToTime = (dateStr: string | undefined) => {
  if (!dateStr) return null
  const _date = new Date(dateStr)
  const [hours, minutes] = [_date.getHours(), _date.getMinutes()]
  const resultHours = hours < 10 ? '0' + hours.toString() : hours.toString()
  const resultMinutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
  return resultHours + ' : ' + resultMinutes
}

export const vodDateParser = (dateStr: string | undefined) => {
  if (!dateStr) return null
  const _date = new Date(dateStr)
  const [month, date] = [_date.getMonth() + 1, _date.getDate()]
  const interval = Math.floor((+new Date() - +_date) / 1000)
  if (interval <= 60) {
    return '방금전'
  } else if (interval <= 3600) {
    return `${Math.floor(interval / 60)}분 전`
  } else if (interval <= 86400) {
    return `${Math.floor(interval / 3600)}시간 전`
  } else if (interval <= 604800) {
    return `${Math.floor(interval / 86400)}일 전`
  }
  return `${month}월 ${date}일`
}

/**
 * @name 시간차이
 * ```ts
 * getTImeDiff('2021-11-16T05:49:50.315Z')
 * 현재 시간과의 차이(sec) 리턴
 * ```
 */
export function getTimeDiff(date: string) {
  const _date = new Date(date)
  const now = new Date().getTime()
  const sec = Math.floor((now - _date.getTime()) / 1000)

  return sec
}

/**
 * @name 시간차이
 * ```ts
 * getTImeDiffToString('2021-11-16T05:49:50.315Z') // 방금전
 * 00분전
 * 00시간 전
 * 00일 전
 * ```
 */
export function getTimeDiffToString(date: string) {
  const _date = new Date(date)
  const now = new Date().getTime()
  const sec = Math.floor((now - _date.getTime()) / 1000)

  if (sec <= 60) {
    return i.t('now')
  } else if (sec <= 3600) {
    return Math.floor(sec / 60) + i.t('mins_ago')
  } else if (sec <= 86400) {
    return Math.floor(sec / 3600) + i.t('hours_ago')
  } else if (sec <= 604800) {
    return Math.floor(sec / 86400) + i.t('days_ago')
  }

  return i.t('date_format.date', {
    year: _date.getFullYear(),
    month: _date.getMonth() + 1,
    day: _date.getDate(),
  })
}

/**
 * @name 숫자포맷변경
 * ```ts
 * toCountFormat(1001) // 1.0천
 * toCountFormat(11000) // 1.1만
 * ```
 */
export function toCountFormat(count: number) {
  /**
   * @param number 치환할 숫자
   * @returns 소수점 1자리까지 고정된 채로 치환된 숫자
   */
  const floorToOneDecimal = (number: number) => {
    return (Math.floor(number * 10) / 10).toFixed(1)
  }

  if (count < 1_000) {
    return count.toLocaleString()
  } else if (count < 10_000) {
    return floorToOneDecimal(count / 1000) + i.t('unit.thousand')
  } else if (count < 100_000_000) {
    return floorToOneDecimal(count / 10_000) + i.t('unit.ten_thousand')
  } else if (count < 1_000_000_000_000) {
    return floorToOneDecimal(count / 100_000_000) + i.t('unit.hundred_million')
  } else {
    return count.toLocaleString()
  }
}

/**
 * @name 난수생성함수
 * ```ts
 * getRandomNumber(0,4); // 0 ~ 4 사이 숫자
 * ```
 */
export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * @name convertSystemMsgToGlobalLang
 * @description 한글이나 ID형식으로 오는 시스템 메시지를 번역해주는 함수
 *
 * 스프린트23-6 배포 이후 생성되는 방송에서는 아래의 내용으로 메시지 전송 받음
 * * 채팅 버전 V2: ID형식
 * * 채팅 버전 V1: 공백이 제거되고 누락된 마침표가 포함된 한글 문구
 *
 * 스프린트23-6 배포 이전 이미 방송 종료된 방송에서는 아래의 내용으로 메시지 전송 받음
 * * 채팅 버전과 관계 없이 공백이 포함되거나 마침표가 누락된 한글 문구
 * @param {Message} systemMessage 한글이나 ID형식의 시스템 메시지를 가진 메시지 데이터 (Required)
 * @returns {Message} 번역된 문구로 변환된 시스템 메시지를 가진 메시지 데이터
 */
export function convertSystemMsgToGlobalLang(systemMessage: Message) {
  const { message, messageType } = systemMessage
  const convertedMessage = { ...systemMessage }
  if (messageType !== TYPE_MESSAGE.ADMIN) return convertedMessage
  switch (message) {
    case '라이브가 시작되었습니다.':
    case 'MSG_BroadCastLiveStart': {
      convertedMessage.message = i.t('broadcast_started')
      break
    }
    case '라이브가 종료되어 VOD로 전환중입니다. ':
    case '라이브가 종료되어 VOD로 전환중입니다.':
    case 'MSG_CatchUpVODConverting': {
      convertedMessage.message = i.t('converting_to_vod')
      break
    }
    case '현재 방송 신호가 끊겼습니다. 잠시만 기다려 주세요':
    case '현재 방송 신호가 끊겼습니다. 잠시만 기다려 주세요.':
    case 'MSG_BroadCastSignalDisconnected': {
      convertedMessage.message = i.t('broadcast_disconnected')
      break
    }
    case '방송 신호가 복구 되었습니다':
    case '방송 신호가 복구 되었습니다.':
    case 'MSG_BroadCastSignalRestored': {
      convertedMessage.message = i.t('broadcast_restored')
      break
    }
    default: {
      const broadcastEndMessage = '라이브가 종료되었습니다.'
      if (convertedMessage.message.includes(broadcastEndMessage)) {
        convertedMessage.message = `${i.t('broadcast_ended')} ${convertedMessage.message.slice(
          broadcastEndMessage.length + 1,
        )}`
      }
      const broadcastEndMessageId = 'MSG_BroadCastLiveEnd'
      if (convertedMessage.message.includes(broadcastEndMessageId)) {
        convertedMessage.message = `${i.t('broadcast_ended')} ${convertedMessage.message.slice(
          broadcastEndMessageId.length + 1,
        )}`
      }
    }
  }
  return convertedMessage
}

export const originCurrencyUnitFn = ({
  currencyId,
  price,
}: {
  currencyId?: string
  price: string
}) => {
  if (price) {
    const priceFormat: string = price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    switch (currencyId) {
      case 'KRW':
        return `${priceFormat}원`
      case 'USD':
        return `$${priceFormat}`
      case 'JPY':
        return `${priceFormat}円`
      case 'VND':
        return `${priceFormat}₫`
      case 'IDR':
        return `Rp${priceFormat}`
      default:
        return `${priceFormat}`
    }
  }
}

export const sellingCurrencyUnitFn = ({
  currencyId,
  sellingPrice,
}: {
  currencyId?: string
  sellingPrice: string
}) => {
  if (sellingPrice) {
    const sellingPriceFormat = sellingPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    switch (currencyId) {
      case 'KRW':
        return `${sellingPriceFormat}원`
      case 'USD':
        return `$${sellingPriceFormat}`
      case 'JPY':
        return `${sellingPriceFormat}円`
      case 'VND':
        return `${sellingPriceFormat}₫`
      case 'IDR':
        return `Rp${sellingPriceFormat}`
      default:
        return `${sellingPriceFormat}`
    }
  }
}

export const throttle = (cbFunction: () => void, time: number) => {
  if (!time) {
    cbFunction()
    return
  }

  let timer: NodeJS.Timeout | null = null
  if (!timer) {
    timer = setTimeout(() => {
      timer = null
      cbFunction()
    }, time)
  } else {
    clearTimeout(timer)
  }
}

export const debounce = (cbFunction: () => void, time: number) => {
  let timer: NodeJS.Timeout
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      cbFunction.apply(this, args)
    }, time)
  }
}

/**
 * 설정한 duration 동안 y만큼 scroll을 smooth 하게 이동시킴
 */
export function scrollToSmooth(y: number, duration: number) {
  const start = window.scrollY
  const distance = y - start

  let startTimestamp: number

  function step(timestamp: number) {
    if (!startTimestamp) startTimestamp = timestamp
    const elapsed = timestamp - startTimestamp

    // 애니메이션 진행률 (0부터 1까지)
    const progress = Math.min(elapsed / duration, 1)

    window.scrollTo(0, start + distance * progress)

    if (elapsed < duration) {
      window.requestAnimationFrame(step)
    }
  }

  window.requestAnimationFrame(step)
}

/**
 * 시간 설정 가능한 scrollTo function
 */

export const scrollTo = async (nowY: number, toBeY: number, duration: number) => {
  return new Promise(() => {
    if (duration <= 0 || Math.abs(nowY - toBeY) <= 1) {
      window.scrollTo({ top: toBeY })
      return
    }

    const needToMove = Math.abs(nowY - toBeY)
    const perTick = (needToMove / duration) * 10
    const movedY = nowY + (nowY < toBeY ? perTick : perTick * -1)

    throttle(() => {
      window.scrollTo({ top: nowY + perTick })
      scrollTo(movedY, toBeY, duration - 10)
    }, 10)
  })
}

/**
 * video currentTime & totalDuration number -> string
 */
export const convertSecondsToTime = (seconds: number): string => {
  if (!seconds) return '00:00:00'

  const roundedSeconds = Math.round(seconds)
  const hours = Math.floor(roundedSeconds / 3600)
  const minutes = Math.floor((roundedSeconds % 3600) / 60)
  const remainingSeconds = roundedSeconds % 60

  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`

  return formattedTime
}

/**
 * make partitionNumber Kinesis
 * The maximum is exclusive and the minimum is inclusive
 */

export const getPartitionNumber = () =>
  String(Math.floor(Math.random() * (1000 - 0))).padStart(4, '0')

/** API 응답 값을 UI에서 사용할 방송 데이터로 변환하는 함수 */
export const convertFrontResponseToBroadcast = ({
  broadcastId,
  broadcastName,
  broadcastStateCode,
  broadcastThumbnailList,
  broadcastTypeCode,
  explanation,
  hashTagList,
  isTest,
  outputInfo,
  partnerId,
  partnerProfileImageUrl,
  productListDetail,
  programmingEndDt,
  programmingStartDt,
  room,
  roomId,
  serviceAdminId,
  serviceManagerId,
  serviceOperatorId,
  shopId,
  shopName,
  shortUrl,
  thumbnailList,
  totalDuration,
}: ApiBroadcastFrontResponse): Broadcast => {
  return {
    bannedUserIdList: room?.banUserInfo?.userId ?? [],
    bannerList: [],
    broadcastEndDt: null,
    broadcastId,
    broadcastName,
    broadcastStartDt: null,
    broadcastStateCategory: broadcastTypeCode,
    broadcastStateCode,
    broadcastThumbnailList,
    broadcastType: null,
    canChat: !!room?.canChat,
    chatCount: room?.chatCounterInfo ?? 0,
    chatVersion: null,
    explanation,
    explanationThumbnailList: [],
    hashTagList,
    highlightList: [],
    insertList: [],
    isPublicOnCollect: null,
    isPublicToVod: null,
    isTest,
    isVodLive: null,
    likeCount: room?.reactionCounterInfo ?? 0,
    majorCategory: null,
    minorCategory: null,
    partnerId,
    partnerName: null,
    productSummaryList: [],
    productDetailList: productListDetail,
    programmingEndDt,
    programmingStartDt,
    roomId,
    scheduleThumbnailList: thumbnailList,
    serviceAdminId,
    serviceManagerId,
    serviceOperatorId,
    shopId,
    shopImageUrl: partnerProfileImageUrl,
    shopName,
    shortUrl,
    timeTagList: [],
    usesBroadcastPassword: null,
    usesCoupon: null,
    usesShare: null,
    usesVodLiveBadge: null,
    videoAspectRatio: null,
    videoPreviewUrl: outputInfo ? outputInfo[0]?.preview : null,
    videoTotalDuration: Number(totalDuration),
    videoUrl: outputInfo ? outputInfo[0]?.path : null,
    viewCount: room.incomingCounterInfo,
    vodLiveNoticeMessage: null,
    vodLiveNoticeType: null,
  }
}
export const convertInternalResponseToBroadcast = ({
  aspectRatio,
  bannerList,
  broadcastCategoryId1,
  broadcastCategoryId2,
  broadcastEndDt,
  broadcastId,
  broadcastName,
  broadcastSecretTypeCode,
  broadcastStartDt,
  broadcastStateCode,
  broadcastThumbnailList,
  broadcastTypeCode,
  broadcastUsePassword,
  chatVersion,
  cueCardList,
  explanation,
  explanationImages,
  hashTagList,
  highlightList,
  isCoupon,
  isExhibitPartner,
  isReplay,
  isTest,
  isVodLive,
  me,
  metaData,
  outputInfo,
  partnerId,
  partnerName,
  partnerProfileImageUrl,
  productList,
  productListDetail,
  programmingEndDt,
  programmingStartDt,
  roomId,
  serviceAdminId,
  serviceManagerId,
  serviceOperatorId,
  shopId,
  shopName,
  shortUrl,
  thumbnailList,
  timeTagList,
  useShare,
}: ApiBroadcastInternalResponse): Broadcast => {
  return {
    bannedUserIdList: [], // room 정보로 업데이트 필수
    bannerList,
    broadcastEndDt,
    broadcastId,
    broadcastName,
    broadcastStartDt,
    broadcastStateCategory: broadcastTypeCode,
    broadcastStateCode,
    broadcastThumbnailList,
    broadcastType: broadcastSecretTypeCode,
    canChat: null, // room 정보로 업데이트 필수
    chatCount: null, // room 정보로 업데이트 필수
    chatVersion,
    explanation,
    explanationThumbnailList: explanationImages,
    hashTagList,
    highlightList,
    insertList: cueCardList,
    isPublicOnCollect: !!isExhibitPartner,
    isPublicToVod: isReplay,
    isTest,
    isVodLive: !!isVodLive,
    likeCount: null, // room 정보로 업데이트 필수
    majorCategory: broadcastCategoryId1,
    minorCategory: broadcastCategoryId2,
    partnerId,
    partnerName,
    productSummaryList: productList,
    productDetailList: productListDetail,
    programmingEndDt,
    programmingStartDt,
    roomId,
    scheduleThumbnailList: thumbnailList,
    serviceAdminId,
    serviceManagerId,
    serviceOperatorId,
    shopId,
    shopImageUrl: partnerProfileImageUrl,
    shopName,
    shortUrl,
    timeTagList,
    usesBroadcastPassword: broadcastUsePassword,
    usesCoupon: isCoupon,
    usesShare: !!useShare,
    usesVodLiveBadge: !!metaData?.isUsingVodLiveBadge,
    videoAspectRatio: aspectRatio,
    videoPreviewUrl: outputInfo ? outputInfo[0].preview : null,
    videoTotalDuration: Number(me?.totalDuration ?? 0),
    videoUrl: outputInfo ? outputInfo[0].path : null,
    viewCount: null, // room 정보로 업데이트 필수
    vodLiveNoticeMessage: metaData?.notice?.message ?? null,
    vodLiveNoticeType: metaData?.notice?.type ?? null,
  }
}
