import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  allBroadcastList: Broadcast[] | null
  allScheduleList: Broadcast[] | null
  broadcastListPerPartner: { partnerId: string; broadcastList: Broadcast[] }[] | null
  isBroadcastLoading: boolean
  isPartnerListLoading: boolean
  isPartnerBroadcastLoading: boolean
  isPartnerScheduleLoading: boolean
  isScheduleLoading: boolean
  partnerList: PartnerType[] | null
  recentBroadcastList: Broadcast[] | null
  recentScheduleList: Broadcast[] | null
  selectedPartnerId: string | null
  selectedPartnerBroadcastList: Broadcast[] | null
  selectedPartnerScheduleList: Broadcast[] | null
}

const initialState: InitialState = {
  allBroadcastList: null,
  allScheduleList: null,
  broadcastListPerPartner: null,
  isBroadcastLoading: true,
  isPartnerListLoading: true,
  isPartnerBroadcastLoading: true,
  isPartnerScheduleLoading: true,
  isScheduleLoading: true,
  partnerList: null,
  recentBroadcastList: null,
  recentScheduleList: null,
  selectedPartnerId: null,
  selectedPartnerBroadcastList: null,
  selectedPartnerScheduleList: null,
}

const appStore = createSlice({
  name: 'app',
  initialState,
  reducers: {
    REQ_GET_PARTNER_LIST(state) {
      state.isPartnerListLoading = true
    },
    REQ_GET_RECENT_BROADCAST_LIST(state) {
      state.isBroadcastLoading = true
    },
    REQ_GET_SCHEDULE_LIST(state) {
      state.isScheduleLoading = true
    },
    REQ_GET_BROADCAST_LIST_OF_PARTNER(
      state,
      { payload: { partnerId } }: PayloadAction<{ partnerId: string }>,
    ) {
      state.isPartnerBroadcastLoading = true
      state.selectedPartnerId = partnerId
    },
    REQ_GET_SCHEDULE_LIST_OF_PARTNER(
      state,
      { payload: { partnerId } }: PayloadAction<{ partnerId: string }>,
    ) {
      state.isPartnerScheduleLoading = true
      state.selectedPartnerId = partnerId
    },
    RES_GET_PARTNER_LIST(state, { payload }) {
      state.partnerList = payload.items
      state.isPartnerListLoading = false
    },
    RES_GET_RECENT_BROADCAST_LIST(state, { payload }) {
      state.recentBroadcastList = payload.items
      state.isBroadcastLoading = false
    },
    RES_GET_SCHEDULE_LIST(state, { payload }) {
      state.recentScheduleList = payload.items
      state.isScheduleLoading = false
    },
    RES_GET_BROADCAST_LIST_OF_PARTNER(state, { payload }) {
      state.selectedPartnerBroadcastList = [...payload.items]
      state.isPartnerBroadcastLoading = false
    },
    RES_GET_SCHEDULE_LIST_OF_PARTNER(state, { payload }) {
      state.selectedPartnerScheduleList = [...payload.items]
      state.isPartnerScheduleLoading = false
    },
    RESET_SELECTED_PARTNER_BROADCAST_LIST(state) {
      state.selectedPartnerBroadcastList = null
      state.selectedPartnerId = null
    },
    RESET_SELECTED_PARTNER_SCHEDULE_LIST(state) {
      state.selectedPartnerScheduleList = null
      state.selectedPartnerId = null
    },
  },
})

export const app = appStore.reducer
export const {
  REQ_GET_PARTNER_LIST,
  REQ_GET_RECENT_BROADCAST_LIST,
  REQ_GET_SCHEDULE_LIST,
  REQ_GET_BROADCAST_LIST_OF_PARTNER,
  REQ_GET_SCHEDULE_LIST_OF_PARTNER,
  RES_GET_PARTNER_LIST,
  RES_GET_RECENT_BROADCAST_LIST,
  RES_GET_SCHEDULE_LIST,
  RES_GET_BROADCAST_LIST_OF_PARTNER,
  RES_GET_SCHEDULE_LIST_OF_PARTNER,
  RESET_SELECTED_PARTNER_BROADCAST_LIST,
  RESET_SELECTED_PARTNER_SCHEDULE_LIST,
} = appStore.actions
