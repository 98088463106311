import { broadcastAPI } from './axios'

const API = {
  getPartnerList: async () => {
    const url = '/internal/partners/tv'
    const res = await broadcastAPI.get(url)
    return res.data.response
  },
  getBroadcastList: async ({
    order = 'desc',
    partnerId,
    returnLimit = 10,
    isScheduled = false,
  }: {
    order?: 'desc' | 'asc'
    partnerId?: string
    returnLimit?: number
    isScheduled?: boolean
  }) => {
    const url = `/internal/broadcasts/tv?${
      partnerId ? `partnerId=${partnerId}` : ''
    }&returnLimit=${returnLimit}&scheduled=${isScheduled}&orderBy=${order}`
    const res = await broadcastAPI.get(url)
    return res.data.response
  },
  getBroadcast: async ({ broadcastId }: { broadcastId: string }) => {
    const res = await broadcastAPI.get(`/internal/broadcast/${broadcastId}`)
    return res.data.response
  },
  getTokenValidation: async (params: {
    broadcastId: string
    accessToken: string
    tokenType: string
  }) => {
    const { broadcastId, ...data } = params
    const res = await broadcastAPI.get(`/internal/token/${broadcastId}/validation`, {
      params: data,
    })

    return res.data
  },
  postToken: async (params: {
    partnerId: string
    nickName: string
    memberType: string
    age: string
    gender: string
    expiresIn: number
    refreshExpiresIn: number
  }) => {
    const res = await broadcastAPI.post(`/internal/token`, params)

    return res.data
  },
}

export default API
