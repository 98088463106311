import React from 'react'
import styled from '@emotion/styled'
import i from 'libs/i18n'

const ChatBeforeLive = () => {
  return (
    <ChatBeforeLiveWrapper>
      <div className="chatBeforeLiveCover">
        <img
          className="chatBeforeLiveIcon"
          src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon_wait.svg?h=200&w=200&q=60&f=webp"
          alt=""
        />
        <div className="chatBeforeLiveTitle">
          <span>{i.t('player_page.before_chat_text')}</span>
        </div>
      </div>
    </ChatBeforeLiveWrapper>
  )
}

const ChatBeforeLiveWrapper = styled.div`
  display: block;
  height: calc(100% - 100px);
  padding-bottom: 100px;

  .chatBeforeLiveCover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .ionIcons {
      color: #ababab;
    }

    .chatBeforeLiveIcon {
      width: 72px;
      height: 72px;
      filter: invert(25%);
    }

    .chatBeforeLiveTitle {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 28px;
      color: #ababab;
      text-align: center;

      span {
        display: block;
        line-height: 50px;
        white-space: pre-wrap;
      }
    }
  }
`

export default ChatBeforeLive
