import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  FocusContext,
  init,
  setFocus,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import ScheduleCard from 'components/card/ScheduleCard'
import Tab from 'components/tab/Tab'
import { SCHEDULE_PAGE_NAME, SCHEDULEPAGE_TABNAME } from 'libs/constants'
import { SMOOTH_DURATION } from 'libs/constants/delay'
import i from 'libs/i18n'
import { RootState } from 'libs/store'
import { SET_NOW_POSITION } from 'libs/store/interaction/interaction.store'
import { getFocusKey, scrollToSmooth } from 'libs/utils'

init({
  throttle: 300,
  throttleKeypresses: true,
})

const EmptyContent = () => {
  return (
    <div className="emptyContents schedule" style={{ color: '#fff' }}>
      <img
        src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon_smile.svg?h=200&w=200&q=60&f=webp"
        alt="empty icon"
      />
      <h1>{i.t('partner_page.no_regist_contents')}</h1>
    </div>
  )
}

const ScheduleList = ({ data, selectedTabName, onPressEnter, onFocusCard }) => {
  if (!data) return <EmptyContent />
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  const when = selectedTabName === SCHEDULEPAGE_TABNAME.TODAY ? today : tomorrow

  const contentList = data
    .filter((schedule) => new Date(schedule.programmingStartDt).getDate() === when.getDate())
    .sort((a, b) => {
      return new Date(a.programmingStartDt).getTime() - new Date(b.programmingStartDt).getTime()
    })

  return (
    <>
      {contentList.map(
        (
          {
            broadcastId,
            broadcastName,
            partnerId,
            programmingStartDt,
            thumbnailList,
            productListDetail,
          },
          index,
        ) => (
          <ScheduleCard
            key={broadcastId}
            broadcastName={broadcastName}
            columnNum={2}
            index={index}
            focusKey={getFocusKey({ section: selectedTabName, partnerId, index })}
            onFocus={onFocusCard}
            onEnterPress={() => {
              onPressEnter({ partnerId, broadcastId, index, section: selectedTabName })
            }}
            programmingStartDt={programmingStartDt}
            representativeProduct={productListDetail?.length === 0 ? null : productListDetail[0]}
            shopName={i.t(`common_brand.${partnerId}`)}
            thumbnail={
              thumbnailList?.filter(({ isRepresentative }) => isRepresentative)[0]?.thumbnailUrl +
                '?h=405&w=540' || ''
            }
            type={'feed'}
          />
        ),
      )}
    </>
  )
}

interface Props {}

const ScheduleContentsContainer: React.FC<Props> = () => {
  const recentScheduleList = useSelector((state: RootState) => state.app.recentScheduleList)
  const lastPositionInfo = useSelector((state: RootState) => state.interaction.nowPosition)

  const { ref, focusKey, focusSelf } = useFocusable({
    focusKey: 'schedule',
  })
  const [selectedTabName, setSelectedTabName] = useState<string>('today')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  const tabList = {
    ['today']: (
      <span key={'today_tab'} className="tab_text">
        {i.t('schedule_page.today_live')} ( {today.getMonth() + 1}.{today.getDate()} )
      </span>
    ),
    ['tomorrow']: (
      <span key={'tomorrow_tab'} className="tab_text">
        {i.t('schedule_page.tomorrow_live')} ( {tomorrow.getMonth() + 1}.{tomorrow.getDate()} )
      </span>
    ),
  }

  const onTabFocus = ({ layout, tabName }) => {
    const { y } = layout
    setSelectedTabName(tabName)
    scrollToSmooth(y, SMOOTH_DURATION)
  }

  const onFocusCard = (layout, extraProps, details) => {
    const { y } = layout
    const { event } = details
    if (!event) {
      scrollTo({ top: y, behavior: 'instant' })
    } else {
      scrollToSmooth(y, SMOOTH_DURATION)
    }
  }

  const onPressEnter = ({ broadcastId, partnerId, index, section }) => {
    const positionInfo = {
      page: SCHEDULE_PAGE_NAME,
      partnerId,
      broadcastId,
      section,
      index,
    }
    dispatch(SET_NOW_POSITION(positionInfo))
    navigate(`/player?broadcastId=${broadcastId}&partnerId=${partnerId}`)
  }

  /**
   * 이전화면에서 선택한 요소 포커싱 useLayoutEffect
   */
  useLayoutEffect(() => {
    const focusKey = getFocusKey(lastPositionInfo)
    const { section } = lastPositionInfo
    if (section === SCHEDULEPAGE_TABNAME.TODAY || section === SCHEDULEPAGE_TABNAME.TOMORROW) {
      setSelectedTabName(section)
      setFocus(focusKey)
      window.history.replaceState({}, document.title)
    } else {
      focusSelf()
    }
  }, [focusSelf, lastPositionInfo])

  return (
    <FocusContext.Provider value={focusKey}>
      <PartnerContentsWrapper ref={ref}>
        <TabListWrapper>
          {Object.values(SCHEDULEPAGE_TABNAME).map((tabName, index) => (
            <Tab
              pageName={SCHEDULE_PAGE_NAME}
              key={tabName}
              index={index}
              onFocus={(layout) => {
                onTabFocus({ layout, tabName })
              }}
              isSelected={selectedTabName === tabName}
              tabName={tabName}
            >
              {tabList[tabName]}
            </Tab>
          ))}
        </TabListWrapper>
        <ScheduleContentsWrapper className="styledScheduleContents">
          <ScheduleList
            selectedTabName={selectedTabName}
            data={recentScheduleList}
            onPressEnter={onPressEnter}
            onFocusCard={onFocusCard}
          />
        </ScheduleContentsWrapper>
      </PartnerContentsWrapper>
    </FocusContext.Provider>
  )
}

const TabListWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.black2};
`

const ScheduleContentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 100px 0px 0;
`
const PartnerContentsWrapper = styled.div`
  .focusedTabBtn {
    input:checked + label > span {
      border-radius: 10px 10px 0 0;
      background-color: #fff;
      color: #16161a;
    }
  }

  .tab_text {
    padding: 21px 50px;
    font-size: 48px;
    font-weight: bold;
    color: #bebebe;
  }

  .none {
    display: none;
  }

  .page_contents_wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0px 0;

    .card {
      margin-bottom: 15px;
    }
  }

  .page_schedule_wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0px 0;
  }

  .emptyContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    width: 100%;

    img {
      margin-bottom: 20px;
    }

    h1 {
      font-size: 60px;
      line-height: 100px;
    }
  }
`

export default ScheduleContentsContainer
