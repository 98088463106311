import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import { useIsImageLoaded } from 'libs/hooks/useIsImageLoaded'
import { dateParser } from 'libs/utils'

interface Props {
  broadcastName?: string
  partnerProfileImageUrl?: string
  programmingStartDt?: string
  broadcastId: string
  shopName?: string
  thumbnail?: string
  partnerId: string
  onEnterPress: (props: object, details: KeyPressDetails) => void
  onFocus: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
  index: number
}

const ScheduleTvCard: React.FC<Props> = ({
  broadcastName,
  partnerProfileImageUrl,
  programmingStartDt,
  broadcastId,
  shopName,
  thumbnail,
  partnerId,
  onEnterPress,
  onFocus,
  index,
}) => {
  const { ref, focused } = useFocusable({
    onEnterPress,
    onFocus,
    focusKey: `next-${partnerId}-${index}`,
    extraProps: {
      section: 'next',
      partnerId,
      broadcastId,
      index,
    },
  })

  const { elementRef, isLoaded } = useIsImageLoaded()

  return (
    <div ref={ref}>
      <StyledScheduleCard isFocused={focused} isShow={isLoaded} ref={elementRef}>
        {index <= 5 && (
          <div
            className="image_wrapper"
            style={{ backgroundImage: `url(${thumbnail + '?q=40&w=280&h=204&f=webp'})` }}
            tabIndex={0}
          >
            <div className="image_shadow" />
            {programmingStartDt && (
              <div className="schedule_date">{dateParser(programmingStartDt)}</div>
            )}
          </div>
        )}
        {index > 5 &&
          (isLoaded ? (
            <div
              className="image_wrapper"
              style={{ backgroundImage: `url(${thumbnail + '?q=40&w=280&h=204&f=webp'})` }}
              tabIndex={0}
            >
              <div className="image_shadow" />
              {programmingStartDt && (
                <div className="schedule_date">{dateParser(programmingStartDt)}</div>
              )}
            </div>
          ) : (
            <StyledSkeletonCard />
          ))}
        <div>
          <div className="info_wrapper">
            <div className="title">{broadcastName}</div>
          </div>
          <div className="info_bottom">
            <img src={`${partnerProfileImageUrl}?h=50&w=50&q=60&f=webp`} alt="" />
            <div className="shop_name">{shopName}</div>
          </div>
        </div>
      </StyledScheduleCard>
    </div>
  )
}

const StyledScheduleCard = styled.div<{ isFocused: boolean; isShow: boolean }>`
  margin: 10px;
  width: 320px;

  .image_wrapper {
    position: relative;
    border: 10px solid #16161a;
    border-radius: 5px;
    width: 300px;
    height: 224px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ${({ isFocused }) =>
      isFocused &&
      css`
        border: 10px solid white;
      `}

    ${({ isShow }) =>
      isShow &&
      css`
        display: block;
      `}

    .schedule_date {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      font-size: 28px;
      color: #ffffff;
      font-weight: bold;
      z-index: 2;
    }

    .image_shadow {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 282px;
      height: 206px;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .info_wrapper {
    display: flex;
    margin-top: 20px;
    width: 100%;
    padding-left: 10px;

    .title {
      font-size: 27px;
      line-height: 30px;
      color: #ffffff;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: keep-all;
      word-wrap: break-word;
    }
  }

  .info_bottom {
    display: flex;
    align-items: center;
    padding: 14px 0 14px 10px;
    img {
      width: 47px;
      height: 47px;
      border-radius: 50%;
    }

    .shop_name {
      margin-top: 0px;
      padding-left: 10px;
      font-size: 27px;
      color: ${(props) => props.theme.brownGrey2};
    }
  }
`

const StyledSkeletonCard = styled.div`
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  width: 320px;
  height: 224px;
  margin: 10px;
  border: 10px solid #16161a;
  background-color: #16161a;
`

export default ScheduleTvCard
