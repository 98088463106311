import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusContext,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import LogoTvCard from 'components/card/LogoTvCard'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

interface BrandListContainerProps {
  onPressEnter: (props: object, details: KeyPressDetails) => void
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
}

const BrandListContainer: React.FC<BrandListContainerProps> = (props) => {
  const { onPressEnter, onFocus } = props
  const partnerList = useSelector((state: RootState) => state.app.partnerList)
  const nowPositionWidth = useSelector((state: RootState) => state.interaction.nowWidth)
  const nowPositionSection = useSelector(
    (state: RootState) => state.interaction.nowPosition.section,
  )
  const { ref, focusKey } = useFocusable({
    focusKey: 'brand',
    onFocus,
  })

  const scrollingRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const onAssetFocus = useCallback(
    (layout, extraProps, details) => {
      const { x: left } = layout
      const { event } = details
      if (scrollingRef.current) {
        scrollingRef.current.scrollTo({ left, behavior: !event ? 'instant' : 'smooth' })
      }
    },
    [scrollingRef],
  )

  useLayoutEffect(() => {
    if (scrollingRef.current && nowPositionSection === 'brand') {
      scrollingRef.current.scrollTo({ left: nowPositionWidth })
    }
  }, [nowPositionSection, nowPositionWidth])

  return (
    <FocusContext.Provider value={focusKey}>
      <BrandSectionWrapper ref={ref}>
        <BrandSectionTitle>{i.t('main_page.brand')}</BrandSectionTitle>
        <BrandSectionScrollingWrapper ref={scrollingRef}>
          <BrandSectionScrollingContent>
            {partnerList?.map((item, index) => {
              const { partnerId, thumbnail } = item
              return (
                <LogoTvCard
                  key={`brand-card-${partnerId}`}
                  image={thumbnail}
                  name={i.t(`common_brand.${partnerId}`)}
                  partnerId={partnerId}
                  onEnterPress={onPressEnter}
                  onFocus={onAssetFocus}
                  index={index}
                />
              )
            })}
          </BrandSectionScrollingContent>
        </BrandSectionScrollingWrapper>
      </BrandSectionWrapper>
    </FocusContext.Provider>
  )
}

export default BrandListContainer

const BrandSectionWrapper = styled.div`
  width: 100%;
`
const BrandSectionTitle = styled.div`
  padding: 10px 0 0 20px;
  font-size: 48px;
  line-height: 64px;
  color: #fff;
  font-weight: 600;
`

const BrandSectionScrollingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  overflow-x: auto;
`
const BrandSectionScrollingContent = styled.div`
  display: flex;
  flex-direction: row;
`
