import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  FocusContext,
  init,
  pause,
  resume,
  setFocus,
  updateAllLayouts,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import Splash from 'components/loading/Splash'
import { SMOOTH_DURATION } from 'libs/constants/delay'
import KEYCODE from 'libs/constants/keycode'
import { RootState } from 'libs/store'
import {
  REQ_GET_PARTNER_LIST,
  REQ_GET_RECENT_BROADCAST_LIST,
  REQ_GET_SCHEDULE_LIST,
} from 'libs/store/app/app.store'
import { SET_FROM_POSITION, SET_NOW_POSITION } from 'libs/store/interaction/interaction.store'
import { getFocusKey, scrollToSmooth } from 'libs/utils'
import BrandListContainer from './Brand.container'
import CloseConfirm from './CloseConfirm'
import CustomCenter from './CustomCenter'
import NextBroadcastListContainer from './NextBroadcast.container'
import RecentBroadcastContainer from './RecentBroadcast.container'
import SettingContainer from './Setting.container'

const HomeContainer: React.FC = () => {
  const isBroadcastLoading = useSelector((state: RootState) => state.app.isBroadcastLoading)
  const isScheduleLoading = useSelector((state: RootState) => state.app.isScheduleLoading)
  const lastPositionInfo = useSelector((state: RootState) => state.interaction.nowPosition)

  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState<boolean>(false)
  const [isOpenCustomCenterModal, setIsOpenCustomCenterModal] = useState<boolean>(false)
  const [isOpenCloseModal, setIsOpenCloseModal] = useState<boolean>(false)
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true)

  const isLoading = isBroadcastLoading || isScheduleLoading

  const { ref, focusKey } = useFocusable({ focusKey: 'home' })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const THROTTLE_MS = 200

  init({
    throttle: THROTTLE_MS,
    throttleKeypresses: true,
  })

  /**
   * 첫 로딩 시에만 전체 방송과 편성표 초기화 useEffect
   */
  useEffect(() => {
    if (!isLoading) {
      setIsInitialLoading(false)
      return
    }
    dispatch(REQ_GET_PARTNER_LIST())
    dispatch(REQ_GET_RECENT_BROADCAST_LIST())
    dispatch(REQ_GET_SCHEDULE_LIST())
  }, [isLoading, dispatch])

  /**
   *  home화면 진입시 포커싱 초기화 useEffect
   */
  useLayoutEffect(() => {
    if (isLoading) return
    dispatch(
      SET_FROM_POSITION({
        page: '',
        section: '',
        broadcastId: '',
        partnerId: '',
        index: 0,
      }),
    )
    if (!lastPositionInfo.partnerId) {
      updateAllLayouts()
      setFocus('recent')
      return
    }

    const focusKey = getFocusKey(lastPositionInfo)
    setFocus(focusKey)
    return
  }, [dispatch, isLoading, lastPositionInfo])

  useEffect(() => {
    const isShowModal = isOpenCloseModal || isOpenCustomCenterModal
    isShowModal ? pause() : resume()
  }, [isOpenCloseModal, isOpenCustomCenterModal])

  /**
   * enter 눌렀을 때 이벤트 매핑
   */
  const onPressEnter = useCallback(
    (option) => {
      const { section, broadcastId, partnerId, index } = option
      const positionInfo = {
        page: 'home',
        partnerId,
        broadcastId,
        section,
        index,
      }

      if (partnerId) {
        dispatch(SET_NOW_POSITION(positionInfo))
      }

      switch (section) {
        case 'setting-language':
          setIsLanguageSelectorOpen(!isLanguageSelectorOpen)
          break
        case 'setting-customer':
          setIsOpenCustomCenterModal(!isOpenCustomCenterModal)
          break
        case 'recent':
          navigate(`/player?broadcastId=${broadcastId}&partnerId=${partnerId}`)
          break
        case 'next':
          if (partnerId === 'more') {
            navigate(`/schedule`)
          } else {
            navigate(`/player?broadcastId=${broadcastId}&partnerId=${partnerId}`)
          }
          break
        case 'brand':
          navigate(`/partners?partnerId=${partnerId}`)
          break
      }
    },
    [isLanguageSelectorOpen, isOpenCustomCenterModal, navigate, dispatch],
  )

  const onFocusCard = (layout, extraProps, details) => {
    const { y } = layout
    const { event } = details

    // 페이지 이동 직후이며 recent container 외의 부분에 포커스가 위치할 경우 instant scroll 사용
    if (!event && !!y) {
      scrollTo({ top: y, behavior: 'instant' })
      return
    }

    // 그 외의 경우 smooth scroll 사용
    scrollToSmooth(y, SMOOTH_DURATION)
  }

  const onStopSplash = () => {
    setIsInitialLoading(false)
  }

  /**
   * 키다운 이벤트 매핑 useEffect
   */
  useEffect(() => {
    const handleKeyDownPartnerContent = (e: KeyboardEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (e.keyCode === KEYCODE.BACK || e.keyCode === KEYCODE.BACK_SPACE) {
        if (isLanguageSelectorOpen) {
          setIsLanguageSelectorOpen(false)
          return
        }
        if (isOpenCustomCenterModal) {
          setIsOpenCustomCenterModal(false)
          return
        }
        setIsOpenCloseModal(!isOpenCloseModal)
      }
    }

    window.addEventListener('keydown', handleKeyDownPartnerContent)
    return () => {
      window.removeEventListener('keydown', handleKeyDownPartnerContent)
    }
  }, [dispatch, isLanguageSelectorOpen, isOpenCloseModal, isOpenCustomCenterModal, navigate])

  return (
    <FocusContext.Provider value={focusKey}>
      <HomeContainerWrapper ref={ref}>
        <SettingContainer
          isLanguageSelectorOpen={isLanguageSelectorOpen}
          setIsLanguageSelectorOpen={setIsLanguageSelectorOpen}
          onPressEnter={onPressEnter}
        />
        <ScrollableRowContainer>
          {isLoading && (
            <Splash
              isShowSplash={location.state?.from ? false : isInitialLoading}
              onPause={onStopSplash}
            />
          )}
          <>
            {!isLoading && (
              <>
                <RecentBroadcastContainer onPressEnter={onPressEnter} onFocus={onFocusCard} />
                <NextBroadcastListContainer onPressEnter={onPressEnter} onFocus={onFocusCard} />
                <BrandListContainer onPressEnter={onPressEnter} onFocus={onFocusCard} />
              </>
            )}
            <>
              {isOpenCloseModal && (
                <CloseConfirm
                  isOpenCloseModal={isOpenCloseModal}
                  setIsOpenCloseModal={setIsOpenCloseModal}
                />
              )}

              {isOpenCustomCenterModal && (
                <CustomCenter
                  isOpenCustomCenterModal={isOpenCustomCenterModal}
                  setIsOpenCustomCenterModal={setIsOpenCustomCenterModal}
                />
              )}
            </>
          </>
        </ScrollableRowContainer>
      </HomeContainerWrapper>
    </FocusContext.Provider>
  )
}

const HomeContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  padding: 40px;
  background-color: #16161a;
`

const ScrollableRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default HomeContainer
