const vi = {
  date_format: {
    date: '{{day}}-{{month}}-{{year}}',
    schedule_time: '{{time,schedule_time}}',
  },

  time: {
    am: 'AM',
    pm: 'PM',
    today: 'Hôm nay',
    tomorrow: 'Ngày mai',
    hour: '{{hour}}Giờ',
    hour_minute: '{{hour}}:{{minute}}',
  },

  unit: {
    hundred_million: '00M',
    issue_count: '{{count}}',
    ten_thousand: '0K',
    thousand: 'K',
    won: '₩{{price}}',
  },

  add: 'Thêm',
  add_banned_terms: 'Vui lòng nhập từ cấm bạn muốn thêm vào.',
  administrator_commented: 'Bạn đã nhận được trả lời.',
  administrator_froze_chat:
    'Trò chuyện trực tiếp đã bị đóng băng!\nChỉ quản trị viên mới có thể tham gia cuộc trò chuyện vào lúc này.',
  administrator_not_issue_coupons: 'Quản trị viên chưa phát hành coupon nào.',
  administrator_unfroze_chat:
    'Trò chuyện trực tiếp đã được hủy đóng băng!\nBạn có thể tham gia trò chuyện ngay bây giờ.',
  age: 'Tuổi',
  agree_personal_info: 'Đồng ý với việc thu thập và sử dụng thông tin cá nhân của chúng tôi',
  alarm_notifications: 'Nhận thông báo',
  all_coupon_issued: 'Bạn đã có tất cả các phiếu giảm giá.',
  already_added_banned_term: 'Đây là từ cấm đã được thêm từ trước',
  auto_report_due_to_spam: 'Bị tự báo cáo do gửi tin rác.',
  available_until_period: 'Có sẵn cho đến {{period}}',
  banned_terms: 'Các từ cấm',
  banned_terms_added: 'Đã thêm vào danh sách các từ cấm trong khi phát sóng.',
  banned_terms_deleted: 'Đã xóa khỏi danh sách các từ cấm trong khi phát sóng.',
  banned_terms_included: 'Có một từ bị chặn trong tin nhắn của bạn.',
  banned_terms_only_applicable_to_live:
    '* Các từ cấm được thêm vào chỉ được áp dụng trong phát sóng LIVE này.',
  broadcast_alarm_notified: 'Một thông báo sẽ được gửi khi buổi phát bắt đầu.',
  broadcast_alarm_success: 'Thông báo về Buổi phát trực tiếp đã được thiết lập.',
  broadcast_disconnected: 'Chúng tôi đã mất tín hiệu buổi phát. Hãy đợi một chút.',
  broadcast_ended: 'Buổi phát trực tiếp đã kết thúc.',
  broadcast_notice: 'Thông báo về buổi phát',
  broadcast_restored: 'Tín hiệu buổi phát đã được khôi phục.',
  broadcast_schedule_can_be_changed:
    'Thời gian phát trực tuyến có thể thay đổi tùy theo tình huống phát trực tuyến.',
  broadcast_started: 'Buổi phát trực tiếp đã bắt đầu.',
  broadcast_unstable: 'Rất tiếc! Buổi phát không ổn định.',
  buttons_at_bottom_right: '※ Nút dưới cùng bên phải >',
  cancel: 'Hủy bỏ',
  cancel_notifications: 'Bạn có muốn hủy thông báo về Buổi phát trực tiếp không?',
  cancel_to_register_purchase_auth_winner: 'Bạn có muốn hủy đăng kí thông tin người trúng thưởng?',
  canceled_broadcast:
    'Rất tiếc! Buổi phát trực tiếp này đã bị hủy.\nHẹn gặp lại bạn trong buổi tiếp theo!',
  cannot_add_sold_out_to_wish_list:
    'Không thể thêm các mặt hàng hết hàng vào danh sách mong muốn của bạn.',
  cannot_be_replayed: 'Rất tiếc! Bạn không thể phát lại buổi phát này.',
  cannot_edit_after_sign_in:
    'Biệt hiệu của bạn sẽ được hiển thị khi bạn tham gia Trò chuyện trực tiếp và thông tin đã nhập sẽ không chỉnh sửa được.',
  cannot_edited_after_sent:
    'Sau khi đã gửi câu trả lời, bạn không thể chỉnh sửa hoặc xóa câu trả lời. Vui lòng kiểm tra trước khi gửi.',
  change_nickname: 'Thay đổi nickname chat',
  change_to_portrait: 'Sử dụng ở chế độ chiều dọc.',
  chat_report: 'Báo cáo tin nhắn này',
  chat_restrict: 'Cấm chat',
  chat_restrict_included: 'Bao gồm cấm chat',
  chat_restricted_by_administrator: 'Bị tắt bởi quản trị viên.',
  chat_restriction: 'Trò chuyện đã bị tắt',
  chat_warning: 'Việc sử dụng ngôn ngữ không phù hợp có thể dẫn đến hạn chế sử dụng dịch vụ.',
  chatroom_frozen: 'Phòng trò chuyện bị đóng băng.',
  check_broadcast_alarm_notifications: 'Bạn có muốn nhận thông báo về Buổi phát trực tiếp không?',
  check_code: 'Kiểm tra mã mục nhập của bạn.',
  click_to_play_info_video: 'Click here to play the instructional video.',
  close: 'Đóng',
  confirm: 'Xác nhận',
  congratulations_on_winning: 'Xin chúc mừng, bạn đã giành được giải thưởng!',
  connection_unstable: 'Rất tiếc! Kết nối không ổn định.\nHãy đợi một chút.',
  contact_exclude_dashes: 'Số liên hệ (không có “-”)',
  converting_to_vod: 'Buổi phát trực tiếp đã kết thúc và đang chuyển đổi sang VOD. ',
  copy: 'Sao chép',
  coupon: 'Phiếu giảm giá',
  coupon_issued: 'Bạn đã nhận được phiếu giảm giá.',
  coupon_received: '(Các) phiếu giảm giá đã nhận được.',
  current_nickname: 'Nickname chat hiện tại',
  days_ago: 'ngày trước',
  delete: 'Xóa',
  delete_comment: 'Xóa bình luận',
  delete_report: 'Xóa',
  deleted_comment_no_revert: 'Bình luận đã xóa không thể khôi phục được.',
  discount: 'Giảm giá',
  do_cancel: 'Hủy bỏ',
  do_copy: 'Sao chép',
  do_delete: 'Xóa',
  do_pin: 'Pin',
  do_report: 'Báo cáo',
  download: 'Tải xuống',
  edit: 'Sửa',
  edit_comment: 'Sửa bình luận của tôi',
  email: 'Email',
  enter_at_lease_one: 'Nhập ít nhất 1 ký tự.',
  enter_at_least_3_purchase_number: 'Nhập số mua hàng có ít nhất 3 chữ số.',
  enter_banned_terms: 'Vui lòng nhập các từ cấm.',
  enter_chat: 'Tham gia Trò chuyện trực tiếp',
  enter_code: 'Nhập mã mục nhập có {{codeLength}} chữ số.',
  enter_contact: 'Nhập thông tin liên hệ của bạn.',
  enter_contact_number_or_email:
    'Nhập số điện thoại hoặc địa chỉ email (không bị lộ khi chưa nhập)',
  enter_email: 'Nhập email của bạn.',
  enter_max_15_chars: 'Enter up to 15 characters',
  enter_max_40_chars: 'Nhập trên 40 ký tự',
  enter_name: 'Nhập tên của bạn.',
  enter_nickname: 'Nhập biệt hiệu của bạn.',
  enter_purchase_authentication_winner: 'Nhập thông tin người trúng thưởng',
  enter_purchase_number: 'Nhập mã số giao dịch mua (đơn đặt hàng) của bạn.',
  enter_reply: 'Nhập câu trả lời của bạn',
  enter_same_message_consecutively: 'Bạn không thể nhập cùng một tin nhắn liên tiếp nhiều lần.',
  enter_short_time_consecutively:
    'Bạn không thể nhập cùng một tin nhắn liên tiếp trong một khoảng thời gian ngắn.',
  enter_too_many_message:
    'Do nhập quá nhiều, bạn đã bị vô hiệu hóa việc gửi tin nhắn trong {{sec}} giây.',
  enter_without_space: 'Nhập không có khoảng trống.',
  entered_with_nickname: '{{nickname}} đã được nhập vào.',
  entered_with_nickname_other: '{{nickname}} và {{other}} đã được nhập vào.',
  event_auto_exit_on_vod: 'Khi chuyển sang VOD, sự kiện sẽ tự động kết thúc.',
  event_can_start_anytime_on_live:
    'Trong khi phát sóng LIVE, bạn có thể bắt đầu sự kiện bất cứ lúc nào.',
  event_management: 'Quản lý sự kiện',
  event_start: 'Bắt đầu sự kiện',
  exit: 'Thoát',
  expose_with_masking_and_max: '(Tối đa 50 người, một phần thông tin bị masking(*) nên đã bị lộ.)',
  failed_issue_coupon: 'Không nhận được phiếu giảm giá.',
  failed_to_enter_message: 'Không thể trò chuyện.',
  failed_to_enter_message_with_error:
    'Không thể trò chuyện. \nLỗi: [Không thể tìm kiếm thông tin đăng nhập. \nHãy đăng nhập lại.]',
  female: 'Nữ',
  fill_out_form_to_chat: 'Nhập thông tin bên dưới để tham gia trò chuyện.',
  freeze_chat: 'Đóng băng trò chuyện',
  full: 'Xem video đầy đủ',
  harassment_abuse: 'Quấy rối hoặc bắt nạt',
  hide_comments: 'Ẩn bình luận',
  highlight: 'Xem điểm nổi bật',
  hours_ago: ' giờ trước',
  info_area: 'Vùng thông tin',
  info_image: 'Hình ảnh',
  info_image_displayed: 'Hình ảnh này đang được hiển thị cho người xem.',
  information_message_to_winner: 'Tin nhắn hướng dẫn trúng thưởng',
  intro: 'Giới thiệu',
  invalid_email_format: 'Địa chỉ email có định dạng sai.',
  invalid_phone_format: 'Số liên hệ có định dạng sai.',
  is_where_see_all_comments:
    'Trong <bold>{{location}}</bold>, bạn có thể xem tất cả các câu trả lời do quản trị viên viết.',
  issue_during_live: 'Bạn chỉ có thể nhận được phiếu giảm giá trong khi phát trực tiếp.',
  join_broadcast: 'Tham gia Buổi phát trực tiếp',
  join_chat_real_time: 'Tham gia cuộc Trò chuyện trực tiếp này',
  join_secret: 'Tham gia Buổi phát trực tiếp bí mật',
  leave_broadcast: 'Bạn có muốn rời buổi phát không?',
  live_comment: 'Trả lời trực tiếp',
  live_comments_tab: 'Tab trả lời trực tiếp',
  live_intro: 'Giới thiệu trực tiếp',
  live_notice: 'Thông báo trực tiếp',
  male: 'Nam',
  mins_ago: 'phút trước',
  more_comments: 'Xem thêm {{count}} bình luận',
  mr: '<bold>{{name}}<bold>',
  must_agree_collect_use_info:
    'Bạn phải đồng ý với việc Thu thập và sử dụng thông tin cá nhân của chúng tôi.',
  must_select_all_option: 'Tất cả các tùy chọn sẽ được chọn.',
  name: 'Tên',
  new_nickname: 'Nickname mới',
  nickname: 'Biệt hiệu',
  no: 'Không',
  no_content: 'Nhập câu trả lời của bạn để gửi.',
  no_coupon: 'Không có phiếu giảm giá.\nHãy đợi những phiếu giảm giá tiếp theo!',
  no_intro: 'Không có phần giới thiệu cho buổi phát này.',
  no_notice: 'Không có thông báo cho buổi phát này.',
  no_products_in_wish_list:
    'Không có mục nào trong danh sách yêu thích của bạn.\nHãy thêm các mục bạn thích.',
  no_reply: 'Không có trả lời cho buổi phát này.',
  no_shortcut: 'Không có lối tắt nào được đăng ký.',
  none: 'Không có',
  note: 'Ghi chú',
  now: 'Ngay trước',
  obscene_message: 'Tin nhắn tục tĩu',
  obscenity_abuse: 'Tục tĩu hoặc bắt nạt',
  only_possible_during_live: 'Chỉ có thể trong khi trực tiếp.',
  optional: 'Tùy chọn',
  other_inappropriate_language: 'Các nhận xét không phù hợp khác',
  participate: 'Tham gia',
  participate_success: 'Bạn đã tham gia sự kiện mua hàng!',
  phone_call_only_mobile: 'Cuộc gọi điện thoại có thể được thực hiện trên thiết bị di động.',
  phone_number: 'Cuộc gọi',
  pin_chat: 'Ghim chat',
  pin_chat_to_top: 'Pin chat to the top?',
  please_enter_nickname: 'Nhập biệt hiệu của bạn.',
  please_input_accurately: 'Nhập chính xác các trường khi chúng được sử dụng cho sự kiện.',
  price_differ_after_live: 'Giá và quyền lợi có thể thay đổi khi Buổi phát trực tiếp kết thúc.',
  products: 'Các mặt hàng khuyến mãi',
  profanity_aggressive_expression: 'Sử dụng ngôn từ tục tĩu hoặc thể hiện sự thù hận',
  purchase: 'Mua',
  purchase_auth_icon_exposed_if_event_on:
    'Khi chuyển sang BẬT bắt đầu sự kiện, biểu tượng xác minh mua hàng sẽ được hiện ra trong trình phát.',
  purchase_auth_result_icon_exposed_if_announcement_on:
    'Khi chuyển sang BẬT tuyên bố người trúng thưởng, biểu tượng kết quả trúng thưởng xác minh mua hàng được hiện ra trên trình phát.',
  purchase_authentication: 'Sự kiện mua hàng',
  purchase_authentication_event: 'Sự kiện mua hàng',
  purchase_authentication_event_winner: 'Những người chiến thắng trong sự kiện mua hàng',
  purchase_authentication_participant_info: 'Thông tin tham gia xác nhận mua hàng',
  push_notifications: 'Thông báo đẩy',
  push_notifications_turned_off_in_my_lotte:
    'Thông báo đẩy đang tắt.\nBật thông báo đẩy trong Lotte của tôi > Cài đặt.',
  real_time_chat: 'Trò chuyện trực tiếp',
  reason_for_reporting: 'Lý do báo cáo',
  receive_coupon: 'Nhận phiếu giảm giá',
  receive_notifications: 'Nhận thông báo',
  receive_notifications_of_broadcast: 'Nhận thông báo về buổi phát',
  receiving_notifications: 'Nhận thông báo',
  recent_shortcut: 'Từ viết tắt được thêm vào gần đây',
  register: 'Đăng ký',
  register_broadcast_alarm: 'Yêu cầu thông báo về Buổi phát trực tiếp',
  register_notice_too: 'It is also added to the LIVE notice.',
  register_success: 'Bạn đã hoàn thành đăng ký trúng thưởng.',
  report_count: 'Báo cáo {{count}}',
  report_list: 'Cuộc trò chuyện đã bị báo cáo',
  report_submitted: 'Báo cáo của bạn đã được nhận.',
  required: 'Được yêu cầu',
  select_reason_for_reporting: 'Chọn lý do báo cáo tin nhắn này.',
  send: 'Gửi',
  send_report: 'Báo cáo đã được chấp nhận',
  service_restricted_with_false_report: 'Báo cáo sai có thể dẫn đến hạn chế sử dụng dịch vụ.',
  set_nickname: 'Nhập biệt hiệu',
  share_broadcast: 'Chia sẻ buổi phát này',
  share_livestream: 'Chia sẻ buổi phát này',
  shortcut: 'Từ viết tắt',
  shortcut_can_use_in_liveconsole: 'The shortcut feature is available in the Live console.',
  show_nickname_on_chat:
    'Biệt hiệu của bạn sẽ được hiển thị khi bạn tham gia Trò chuyện trực tiếp và thông tin đã nhập sẽ không chỉnh sửa được.',
  sign_in: 'Đăng nhập',
  sign_in_chat: 'Tham gia Trò chuyện trực tiếp',
  sign_in_info_timed_out: 'Thông tin đăng nhập của bạn đã hết hạn. \nVui lòng đăng nhập lại.',
  signed_in: 'Bạn đã đăng nhập với {{nickname}}.',
  sold_out: 'Đã bán hết',
  spam: 'Tin nhắn rác',
  speed: 'Tốc độ X',
  stop: 'Dừng Gửi đi',
  subscribe: 'Đăng ký',
  subscribe_shop: 'Bạn có muốn đăng ký tham gia Buổi phát trực tiếp của {{shopName}} không?',
  subscriber_with_count: 'Người đăng ký {{count}}',
  subscribing: 'Đã đăng ký',
  touch_turn_on_sound: 'Nhấn để bật âm thanh.',
  transmit: 'Chia sẻ',
  turn_on_notifications: 'Bật thông báo',
  unable_find_sign_in_path: 'Không xác định được đường dẫn đăng nhập.',
  unfreeze_chat: 'Mở đóng băng chat',
  unpin: 'Bỏ ghim chat',
  unpin_chat: 'Confirm',
  unpin_chat_to_top: 'Are you sure to unpin the chat?',
  unsubscribe_shop: 'Bạn có muốn hủy đăng ký tham gia Buổi phát trực tiếp của {{shopName}} không?',
  up_to_discount: 'Giảm giá lên đến {{discount}}',
  up_to_discount_upon_purchasing: 'Giảm giá lên đến {{discount}} khi mua tối thiểu {{threshold}}',
  upon_purchasing: 'Đối với giao dịch mua ít nhất là {{threshold}}',
  url_copied: 'URL đã được sao chép.',
  verify: 'Tham gia',
  view_more: 'Xem thêm',
  view_on_app: 'Xem trên ứng dụng',
  view_on_web: 'Xem trên web',
  view_terms: 'Xem Điều khoản và điều kiện.',
  viewable_full_horizontal_video:
    'Video chiều ngang sẽ được xem toàn màn hình khi xem theo chiều ngang.',
  wait_until_live_start:
    'Bạn có thể tham gia Trò chuyện trực tiếp sau khi Buổi phát trực tiếp bắt đầu.\nVui lòng đợi đến lúc đó.',
  winner_add: 'Nhập thông tin người trúng thưởng',
  winner_announcement: 'Tuyên bố người trúng thưởng',
  winner_announcement_can_control_after_add:
    'Sau khi nhập thông tin người trúng thưởng bạn có thể BẬT/TẮT tuyên bố người trúng thưởng.',
  winner_info_can_modify_after_vod:
    'Ngay cả khi chuyển sang VOD, bạn cũng có thể nhập/chỉnh sửa thông tin người trúng thưởng.',
  winner_information: 'Thông tin người trúng thưởng',
  winner_management: 'Quản lý người trúng thưởng',
  wish_list: 'Danh sách mong muốn',
  wish_to_delete: 'Bạn có muốn xóa không?',
  wish_to_receive_notifications: 'Bạn có muốn nhận thông báo về Buổi phát trực tiếp không?',
  wish_to_sign_in: 'Bạn có muốn đăng nhập không?',
  write_comments: 'để lại một bình luận',
  writing_comments: 'để lại một bình luận cho {{nickname}}',
  yes: 'Có',

  common_brand: {
    ebay: 'Gmarket',
    auction: 'Auction',
    samsung: 'SAMSUNG',
    ldfs: 'Hotel Lotte',
    shinsegaelive: 'SHINSEGAE Live',
    lfmall: 'LF Mall',
    yanolja: 'yanolja',
    momq: 'MOMQ',
    hanatour: 'HanaTour',
    hanssem: 'HANSSEM',
    coway: 'COWAY',
    ellotte: 'Lotte 100',
    lotteon: 'Lotte On',
    livart: 'Livart',
    dongwonmall: 'Dongwon Mall',
    'lg-uplus': 'LG Uplus',
    abcmart: 'ABC Mart',
    posa: 'ePost Mall',
    innisfree: 'Inisfree',
    happyprince: 'Happy Prince',
    pastelmall: 'Pastel Mall',
  },
  main_page: {
    next_live: 'Tiếp theo TRỰC TIẾP',
    top_10_live: '10 TRỰC TIẾP hàng đầu',
    brand: 'Thương hiệu',
    more: 'thêm',
    policy_context:
      'Mobidoo Inc. là một sàn giao dịch đặt hàng qua thư và không phải là một bên tham gia trong giao dịch đặt hàng qua thư. Do đó, họ không chịu trách nhiệm đối với hàng hóa, giao dịch, giao hàng, hoàn tiền hoặc các giao dịch mua sắm qua thư khác, và trách nhiệm đó nằm ở phía các bên thứ ba, chẳng hạn như những người yêu cầu sàn giao dịch đặt hàng qua thư.',
    close_title: 'Thoát khỏi SauceTV?',
    close_cancel: 'trở lại',
    close_confirm: 'Thoát khỏi SauceTV',
    info_version: 'đời',
    info_device: 'Thiết bị kĩ thuật',
    info_language: 'ngôn ngữ',
    info_platform: 'Nền tảng',
    info_region: 'vùng',
    info_contact: 'Thoát khỏi SauceTV',
    info_close: 'đóng',
  },
  schedule_page: {
    today_live: 'TRỰC TIẾ',
    tomorrow_live: 'Ngày Mai TRỰC TIẾP',
    no_regist_live: 'Không có nội dung',
  },
  partner_page: {
    contents_tab: 'nội dung',
    schedule_tab: 'lịch trình',
    no_regist_contents: 'Không có nội dung.',
    no_regist_live: 'Không có nội dung.',
  },
  player_page: {
    entered_with_nickname: 'Đã nối {{nickname}}',
    entered_with_nickname_other: '{{nickname}} và {{other}} người khác đã tham gia',
    preset_1: 'Xin chào',
    preset_2: 'thích',
    preset_3: 'Mắc cười quá',
    preset_4: 'thất vọng',
    preset_5: 'buồn chán',
    preset_6: 'sự vỗ tay',
    preset_7: 'ngạc nhiên',
    icon_notice: 'Thông báo',
    icon_chat: 'Trò chuyện',
    modal_qr_scan_info: 'QUÉT MÃ NÀY',
    modal_live_intro_tab: 'thông tin',
    modal_live_intro_no_cont: 'Không có nội dung.',
    modal_live_notice_tab: 'thông báo',
    modal_live_notice_no_cont: 'Không có nội dung.',
    modal_live_reply_tab: 'trả lời',
    modal_live_reply_no_cont: 'Không có nội dung.',
    modal_close: 'đóng',
    banner_default_text: '더 많은 방송 보기!',

    product_title: 'đang bán',
    product_info_text: 'Giá cả và lợi ích có thể thay đổi sau khi buổi trực tiếp kết thúc.',
    product_soldout: 'hết hàng',
    before_chat_text:
      'Khi cuộc trò chuyện trực tiếp bắt đầu, bạn có thể tham gia.  \nXin vui lòng đợi cho đến khi buổi trực tiếp bắt đầu.',
    before_notice: 'Thông báo',
  },
}

export default vi
