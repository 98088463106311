import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  FocusableComponentLayout,
  FocusContext,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation'
import MoreCard from 'components/card/MoreCard'
import ScheduleTvCard from 'components/card/ScheduleTvCard'
import i from 'libs/i18n'
import { RootState } from 'libs/store'

interface NextBroadcastContainerProps {
  onPressEnter: (props: object, details: KeyPressDetails) => void
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void
}

const NextBroadcastListContainer: React.FC<NextBroadcastContainerProps> = (props) => {
  const { onPressEnter, onFocus } = props
  const recentScheduleList = useSelector((state: RootState) => state.app.recentScheduleList)
  const partnerList = useSelector((state: RootState) => state.app.partnerList)
  const nowPositionWidth = useSelector((state: RootState) => state.interaction.nowWidth)
  const nowPositionSection = useSelector(
    (state: RootState) => state.interaction.nowPosition.section,
  )
  const { ref, focusKey } = useFocusable({
    focusKey: 'next',
    onFocus,
  })

  const scrollingRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const onAssetFocus = useCallback(
    (layout, extraProps, details) => {
      const { x: left } = layout
      const { event } = details
      if (scrollingRef.current) {
        scrollingRef.current.scrollTo({ left, behavior: !event ? 'instant' : 'smooth' })
      }
    },
    [scrollingRef],
  )

  useLayoutEffect(() => {
    if (scrollingRef.current && nowPositionSection === 'next') {
      scrollingRef.current.scrollTo({ left: nowPositionWidth })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FocusContext.Provider value={focusKey}>
      <NextSectionWrapper ref={ref}>
        <NextSectionTitle>{i.t('main_page.next_live')}</NextSectionTitle>
        <NextSectionScrollingWrapper ref={scrollingRef}>
          <NextSectionScrollingContent>
            {!!recentScheduleList &&
              recentScheduleList?.map((data, index) => {
                const {
                  broadcastId,
                  broadcastName,
                  partnerId,
                  programmingStartDt,
                  broadcastThumbnailList,
                } = data as Broadcast

                const partnerInfo = partnerList?.find(
                  (partnerInfo) => partnerInfo.partnerId === partnerId,
                )

                return (
                  <ScheduleTvCard
                    key={`next-card-${broadcastId}`}
                    broadcastName={broadcastName}
                    partnerProfileImageUrl={partnerInfo?.thumbnail}
                    programmingStartDt={programmingStartDt}
                    partnerId={partnerId}
                    broadcastId={broadcastId}
                    shopName={i.t(`common_brand.${partnerId}`)}
                    thumbnail={broadcastThumbnailList[0]?.thumbnailUrl}
                    onEnterPress={onPressEnter}
                    onFocus={onAssetFocus}
                    index={index}
                  />
                )
              })}
            <MoreCard
              size={{ w: '248px', h: '204px', margin: '30px 40px 0 20px' }}
              path={{
                section: 'next',
                partnerId: 'more',
                broadcastId: '',
                index: recentScheduleList ? recentScheduleList?.length : 0,
              }}
              onEnterPress={onPressEnter}
              onFocus={onAssetFocus}
            />
          </NextSectionScrollingContent>
        </NextSectionScrollingWrapper>
      </NextSectionWrapper>
    </FocusContext.Provider>
  )
}

export default NextBroadcastListContainer

const NextSectionWrapper = styled.div`
  width: 100%;
`
const NextSectionTitle = styled.div`
  padding: 30px 0 0 20px;
  font-size: 48px;
  line-height: 64px;
  color: #fff;
  font-weight: 600;
`

const NextSectionScrollingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
`

const NextSectionScrollingContent = styled.div`
  display: flex;
  flex-direction: row;
`
