import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Layout from 'wrappers/Layout'
import KEYCODE from 'libs/constants/keycode'
import { RootState } from 'libs/store'
import { REQ_GET_SCHEDULE_LIST } from 'libs/store/app/app.store'
import { SET_NOW_POSITION } from 'libs/store/interaction/interaction.store'
import ScheduleContentsContainer from './ScheduleContents.container'

const ScheduleContainer: React.FC = () => {
  const recentScheduleList = useSelector((state: RootState) => state.app.recentScheduleList)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!recentScheduleList) {
      dispatch(REQ_GET_SCHEDULE_LIST())
    }
  }, [dispatch, recentScheduleList])

  useEffect(() => {
    const handleKeyDownScheduleContent = (e: KeyboardEvent) => {
      e.preventDefault()
      e.stopPropagation()

      switch (e.keyCode) {
        case KEYCODE.BACK:
        case KEYCODE.BACK_SPACE:
          dispatch(
            SET_NOW_POSITION({
              page: 'home',
              section: 'next',
              broadcastId: '',
              partnerId: 'more',
              index: recentScheduleList?.length || 0,
            }),
          )
          navigate('/')
          break
      }
    }
    window.addEventListener('keydown', handleKeyDownScheduleContent)

    return () => {
      window.removeEventListener('keydown', handleKeyDownScheduleContent)
    }
  }, [dispatch, navigate, recentScheduleList])
  return (
    <Layout>
      <ScheduleContentsContainer />
    </Layout>
  )
}

export default ScheduleContainer
