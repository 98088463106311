import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import useBroadcastStatus from 'libs/hooks/useBroadcastStatus'
import { RootState } from 'libs/store'
import ChatAdminNotice from './ChatAdminNotice'
import ChatRoomInfo from './ChatRoomInfo'

interface Props {
  messageList: Message[]
}

const ChatContent: React.FC<Props> = ({ messageList }) => {
  const lastElRef = useRef<null | HTMLDivElement>(null)
  const currentNotice = useSelector((state: RootState) => state.player.currentNotice)
  const { isLive } = useBroadcastStatus()

  useEffect(() => {
    if (lastElRef?.current) {
      lastElRef.current.scrollIntoView()
    }
  }, [messageList])

  return (
    <ChatContentWrapper styleProps={{ isLive, currentNotice }}>
      <ChatInfoWrapper>
        <ChatAdminNotice />
        <ChatRoomInfo />
      </ChatInfoWrapper>
      <div className="chatContSpace">
        {messageList.map((v, idx: number) => (
          <div className="chatContList" key={idx}>
            <div className="chatContListDetail">
              {v.messageType === 1 ? (
                <p className="chatContText adminUser">
                  <span className="adminName">{v.userNick}</span>&nbsp;{' '}
                  <img
                    className="badgeIcon"
                    src="https://d2kkk8cefusmqi.cloudfront.net/icons/Icon_badge.svg?h=200&w=200&q=60&f=webp"
                    alt="badge icon"
                  />
                  {'  '}
                  &nbsp;{v.message}
                </p>
              ) : (
                <p className="chatContText guestUser">
                  <span className="guestName">{v.userNick}</span>&nbsp;&nbsp;
                  {v.message}
                </p>
              )}
            </div>
          </div>
        ))}
        <p className="chatContText guestUser" ref={lastElRef}>
          <span className="guestName">&nbsp;</span>&nbsp;&nbsp;
        </p>
      </div>
    </ChatContentWrapper>
  )
}

const ChatInfoWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: calc(100% / 3 - 50px);
`

interface StyleProps {
  isLive: boolean
  currentNotice: Message | null
}

const ChatContentWrapper = styled.div<{ styleProps: StyleProps }>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.025);
  overflow: hidden;

  .chatContSpace {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    ${({ styleProps }) =>
      styleProps.isLive &&
      styleProps.currentNotice &&
      css`
        margin-top: 100px;
      `}

    ${({ styleProps }) =>
      styleProps.isLive &&
      !styleProps.currentNotice &&
      css`
        margin-top: 50px;
      `}

    .chatContList {
      margin-top: 10px;
      height: auto;

      .chatContListDetail {
        .chatContText {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          font-size: 20px;
          color: #999;

          &.adminUser {
            color: #00aaff;
            font-weight: 600;

            .badgeIcon {
              height: 24px;
              filter: invert(30%);
              fill: #00aaff;
            }
          }

          &.guestUser {
            font-weight: 600;
          }

          .guestName {
            font-weight: 400;
          }

          img {
            height: 40px;
          }
        }
      }
    }
  }
`

export default ChatContent
